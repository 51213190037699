#footer {
  background-color: #dcdddf;
  .container {
    max-width: 1480px;
  }
}

#footer .footer-block {
  padding: 60px 0 50px 0;
}

#footer .footer-logo {
  display: inline-block;
  vertical-align: top;
}

#footer .footer-link {
  padding: 0;
  margin: 0;
  list-style-type: none;
  min-height: 52px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

#footer .footer-link li {
  position: relative;
  display: inline-block;
  padding: 0 6px;
}

#footer .footer-link li:first-child {
  padding-left: 0;
}

#footer .footer-link li:last-child:after {
  display: none;
}

#footer .footer-link li a {
  color: #646466;
  font-size: 16px;
  border-bottom: 1px solid #333;
  line-height: 1;
  display: block;
}

#footer .footer-link li a:hover {
  color: #e85611;
}

#footer .copyright {
  font-size: 12px;
  padding-top: 12px;
  line-height: normal;
}

#footer .isi-block .sitepanel .panel-title {
  display: none;
}
