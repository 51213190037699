/* =homepage css
========================================================================================*/

/*
@media(min-width:992px) and (max-width:1200px) {
	.home .banner-content.text-right{width: 45vw; float: right; }
	.home .banner-content h1 {font-size: 62px;}
	.home .banner-content h4 {font-size: 48px;}
	.home .highlight-text {font-size: 16px;}

} */

/* @media(min-width:1201px) and (max-width:1280px) {
	.home .banner-content.text-right {width: 45vw; float: right;}
	.home .banner-content h1 {font-size: 72px;}
	.home .banner-content h4 {font-size: 52px;}
	.home .highlight-text {font-size: 18px; width:auto;}
	.home .boy-img img {left: -130px;  top: -360px;}
} */

.home-top .banner-content h1 {
  text-transform: none;
  margin-bottom: 20px;
}

.home-top .banner-content h4 {
  margin-bottom: 20px;
}

.home-top .banner-content h3 {
  font-family: "Open Sans Condensed";
  color: #003d65;
  text-transform: none;
  margin-bottom: 30px;
}

.home-banner {
  margin-bottom: 140px;
}

.home-banner .container {
  position: relative;
}

.home-banner .banner-overlay-img {
  position: absolute;
  bottom: 0;
  right: 0;
}

.home-banner .overlay-box.left-bottom-position {
  bottom: -100px;
}

.discover-data {
  margin: 80px 0;
}

.discover-data h2 {
  color: #e85611;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 60px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
}

.discover-data .progress-circle {
  position: relative;
  height: 50px;
  width: 50px;
  margin: 0;
  top: 0;
  left: 0;
  margin-bottom: 15px;
}

.discover-data .progress-circle div {
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 2px solid #08b0a0;
}

.discover-data .progress-circle div span {
  content: "";
  position: absolute;
  font-size: 0;
  height: 30px;
  width: 30px;
  line-height: 125px;
  left: 50%;
  top: 50%;
  text-align: center;
  border-radius: 50%;
  background-color: #003d65;
  box-shadow: none;
  border: 2px solid #08b0a0;
  color: #003d65;
  font-weight: 700;
}

.discover-data .progress-circle .rotate {
  clip: rect(0 25px 50px 0);
}

.discover-data .progress-circle .left {
  clip: rect(0 25px 50px 0);
  opacity: 1;
}

.discover-data .progress-circle .right {
  clip: rect(0 25px 50px 0);
  transform: rotate(180deg);
  opacity: 0;
}

@keyframes toggle {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.discover-data .progress-bar-chart {
  height: 55px;
  width: 54px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  padding-bottom: 4px;
  border-bottom: 1px solid #0bb0a0;
  margin-bottom: 9px;
}

.discover-data .discover-content .progress-bar-chart .medium-rect {
  transform-origin: 0 100%;
}
.discover-data .discover-content .progress-bar-chart .short-rect {
  transform-origin: 0 100%;
}
.discover-data .discover-content .progress-bar-chart .tall-rect {
  transform-origin: 0 100%;
}

.discover-data .discover-content.shown .progress-bar-chart .medium-rect {
  animation: grow 1.5s linear;
}

.discover-data .discover-content.shown .progress-bar-chart .short-rect {
  animation: grow 1s linear;
}

.discover-data .discover-content.shown .progress-bar-chart .tall-rect {
  animation: grow 2.5s linear;
}
@keyframes grow {
  0% {
    transform: scaleY(0);
  }
  25% {
    transform: scaleY(0.5);
  }
  50% {
    transform: scaleY(1);
  }
  75% {
    transform: scaleY(1.2);
  }
  100% {
    transform: scaleY(1);
  }
}

.discover-data .study-numbers {
  color: #0bb0a0;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 31px;
  text-align: left;
  text-transform: uppercase;
  margin-top: 5px;
  margin-left: 50px;
}

.discover-bg {
  background-color: #003d65;
  padding: 30px 50px 35px;
}

.discover-bg h3 {
  color: #0bb0a0;
}

.discover-bg p {
  color: #fff;
}
