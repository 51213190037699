.recognized-options {
  background: $blue;
  padding: 30px 0 60px;
  margin-top: 0 !important;
  color: #fff;
  .col-md-4 {
    position: relative;
    text-align: center;
    padding-left: 60px;
    padding-right: 60px;
    img {
      display: block;
      height: 70px;
      margin: 0 auto 10px auto;
    }
    &:nth-child(n + 2):before {
      content: 'OR';
      position: absolute;
      top: 50%;
      left: -20px;
      font-size: 25px;
      font-weight: bold;
      color: '#fff';
    }
    @media (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 80px;
      &:last-child {
        margin-bottom: 0;
      }
      &:nth-child(n):before {
        left: 50%;
        top: -65px;
        transform: translateX(-50%);
      }
    }
  }
}

.list-data {
  ul {
    margin: 0 0 0 23px;
    padding: 0;
    &:after {
      content: '';
      display: block;
      clear: both;
    }
    @media (max-width: $mobile-breakpoint) {
      margin-left: 30px;
    }

    li {
      margin-bottom: 10px;
      color: #575656;
      font-weight: 600;
      width: 50%;
      float: left;
      position: relative;
      padding-left: 5px;
      padding-right: 20px;
      @media (max-width: $mobile-breakpoint) {
        float: none;
        width: 100%;
      }
    }

    li span {
      font-weight: 400;
      display: inline-block;
    }
    li .sublisting {
      list-style-type: disc;
      margin-top: 10px;
      li {
        width: 100%;
      }
      li::marker {
        font-size: 14px;
        color: $grey;
      }
    }

    li.note-listing {
      margin-left: -26px;
      list-style: none;
    }
  }
  span.note {
    font-size: 12px;
    font-weight: 400;
  }
  span.note {
    font-size: 12px !important;
    font-weight: 400;
  }
}
