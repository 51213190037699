.intro-block.pivotal-trial {
  @media (max-width: $mobile-breakpoint) {
    padding-top: 0;
    h3 {
      font-size: 20px;
    }
  }
}

.study-population {
  padding: 50px 0;
  margin-top: 0;

  .container {
    max-width: 1400px;
  }
  p.text-center {
    max-width: 800px;
    margin: 20px auto;
  }
  .population-section {
    margin-top: 60px;
    text-align: center;
    flex-wrap: nowrap;
    p {
      font-size: 14px;
      text-align: center;
      margin-top: 20px;
    }
    h4 {
      margin-top: 20px;
      @media (max-width: $mobile-breakpoint) {
        font-size: 16px;
        line-height: 22px;
      }
    }
    figure {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;
      height: 90px;
      max-width: 90px;
      margin-left: auto;
      margin-right: auto;
      img {
        height: fit-content;
      }
    }
    .progress-circle {
      position: relative;
      height: 90px;
      width: 90px;
      margin: 0 auto;
      top: 0;
      left: 0;
    }
    .progress-circle div {
      position: absolute;
      border-radius: 50%;
      border: 2px solid #174775;
      height: 88px;
      width: 88px;
      margin: 0 auto;
    }
    .progress-circle div span {
      position: absolute;
      font-size: 17px;
      height: 60px;
      width: 60px;
      line-height: 60px;
      left: 50%;
      top: 50%;
      text-align: center;
      border-radius: 50%;
      background-color: white;
      box-shadow: none;
      border: 2px solid #174775;
      color: #174775;
      font-weight: 700;
    }
    .progress-circle .rotate {
      clip: rect(0 44px 88px 0);
    }
    .progress-circle .left {
      clip: rect(0 44px 88px 0);
      opacity: 1;
    }
    .progress-circle .right {
      clip: rect(0 44px 88px 0);
      transform: rotate(180deg);
      opacity: 0;
    }
    @media (max-width: 767px) {
      flex-wrap: wrap;
      .col-12 {
        padding-left: 30px;
        padding-right: 30px;
        p {
          margin-bottom: 30px;
        }
      }
      .col-xl-3 {
        padding: 30px 15px;
      }
    }
  }
}

.podcast {
  margin-top: 200px;
  .highlight {
    position: relative;
    max-width: 500px;
    margin: -200px auto 0;

    img {
      margin-bottom: 40px;
      @media (max-width: $mobile-breakpoint) {
        max-width: 250px;
      }
    }
    p {
      margin: 0;
      padding: 0 20px;
      font-weight: bold;
      @media (max-width: $mobile-breakpoint) {
        font-size: 14px;
      }
    }
    .site-button {
      font-size: 12px;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: -10px;
      left: 50%;
      width: 75%;
      height: 10px;
      background-color: #fff;
      transform: translateX(-50%);
    }
    &:after {
      top: 100%;
      background-color: #f2f5f7;
    }
  }
}
