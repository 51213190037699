.support-resources {
  p {
    @media (max-width: $mobile-breakpoint) {
      font-size: 14px;
    }
  }
}

.support-resources-page {
  padding: 30px 0;

  .survival-patients-text {
    margin-left: 30px;
  }
}

@media (max-width: $mobile-breakpoint) {
  .support-resources-page {
    padding-bottom: 0 !important;
  }

  .support-resources-page+.resources-wrap {
    padding-top: 0;
  }
}

.listen-now {
  position: relative;
  margin: 0 -15px;

  .row {
    margin: 12px -5px;
    padding: 30px;
    background-color: $light-grey;
  }

  .col-md-8 {
    padding-right: 30px;
  }

  .col-md-4 {
    .wrapper {
      padding: 20px 40px;

      a {
        color: $blue;
        font-size: 30px;
        font-weight: bold;
        text-decoration: underline;

        &::after {
          content: '';
          display: inline-block;
          background-image: url(../images/Listen_Now_Button.png);
          background-size: contain;
          background-repeat: no-repeat;
          height: 25px;
          width: 25px;
          margin-left: 10px;
          vertical-align: middle;
        }
      }
    }
  }

  h2,
  h3 {
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 67px;
    font-weight: bold;
    color: $blue;
  }

  h2 {
    margin-bottom: 20px;
    font-size: 67px;

    img {
      vertical-align: baseline;
      margin-right: 20px;
    }
  }

  h3 {
    margin-bottom: 20px;
    font-size: 35px;
    line-height: 47px;
    text-transform: none;
  }

  p {
    line-height: 22px;
  }

  @media (max-width: $mobile-breakpoint) {
    width: calc(100% + 30px);

    .row {
      margin: 0px -15px;
      padding: 30px 10px;
    }

    .col-md-8 {
      padding-right: 15px;
    }

    h2 {
      font-size: 50px;

      img {
        width: 28px;
      }
    }

    h3 {
      font-size: 20px;
      line-height: 27px;
    }

    .col-md-4 .wrapper a {
      font-size: 20px;
    }
  }
}

a.subscribe {
  position: relative;
  border: 1px solid $blue;
  border-radius: 4px;
  padding: 5px 10px;
  color: $blue;

  &::after {
    content: '';
    position: absolute;
    right: -15px;
    bottom: -13px;
    display: block;
    width: 23px;
    height: 23px;
    background-image: url(../images/mouse-pointer.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.also-available {
  display: flex;
  align-items: center;

  .icons {
    padding-right: 20px;
    border-right: 1px solid #595959;

    p {
      margin: 0;
    }
  }

  .text {
    padding-left: 20px;

    p {
      max-width: 470px;
      line-height: 22px;
      margin: 0;
    }
  }

  @media (max-width: $mobile-breakpoint) {
    display: block;

    .icons {
      padding-bottom: 20px;
      border: none;
      border-bottom: 1px solid #595959;
    }

    .text {
      padding: 20px 0;
    }
  }
}

.resources {
  margin-bottom: 40px;

  .resource {
    padding: 30px 0;
    border-bottom: 2px solid #979797;

    h4 {
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
    }

    p {
      margin-bottom: 10px;
    }

    a {
      margin-top: 0;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      color: #575656;
    }
  }

  .resource-title {
    background: #666;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    padding: 15px 40px;
    text-transform: uppercase;
  }

  &.orange {
    .resource-title {
      background: $orange;
    }

    .resource h4,
    .resource a i {
      color: $orange;
    }
  }

  &.teal {
    .resource-title {
      background: $teal;
    }

    .resource h4,
    .resource a i {
      color: $teal;
    }
  }

  &.light-blue {
    .resource-title {
      background: $light-blue;
    }

    .resource h4,
    .resource a i {
      color: $light-blue;
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .resource-title {
      margin-left: -15px;
      margin-right: -15px;
      text-align: center;
      font-size: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .resource {
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
    }
  }
}
