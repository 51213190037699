@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700&display=swap');

/*========================================================================================*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

figure {
  margin: 0;
}

/* =Deafult Tag & General Classes
========================================================================================*/
html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  font-smoothing: antialiased;
  /* Fix for webkit rendering */
  -webkit-text-size-adjust: 100%;
}

body {
  font: 16px/1.45 'Open Sans', sans-serif;
  color: #505061;
}

body.popactive {
  overflow: hidden;
}

body.popactive #wrapper:before {
  content: '';
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background: rgba(0, 25, 76, 0.8);
  z-index: 2;
}

#wrapper {
  overflow: hidden;
  position: relative;
  padding-top: 142px;
}

.container {
  max-width: 1470px;
}

.container {
  max-width: 1280px;
}

.container.owl-carousel-wrapper {
  max-width: 1470px;
}

.graybg1 {
  background: #dcdddf;
}

.graybg2 {
  background: #eef1f3;
}

.box-shadow {
  box-shadow: 0px 0px 43px rgba(0, 0, 0, 0.08);
}

.box-shadow-bottom {
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.08);
}

.heading1 {
  font-size: 92px;
  color: #003d65;
  line-height: 1;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'Open Sans Condensed';
}

.heading2 {
  font-size: 32px;
  color: #00a3a0;
  line-height: 46px;
  margin-bottom: 20px;
  font-weight: 600;
}

.page-title {
  font-size: 22px;
  color: #5b5c5c;
  font-weight: 700;
  margin-bottom: 10px;
}

.highlight-text {
  font-size: 23px;
  line-height: 30px;
  color: #575656;
  font-weight: 400;
  margin-bottom: 30px;
  width: 32%;
  float: right;
  line-height: 1.5;
}

.highlight-text.primary-color {
  color: #00194c;
}

.site-banner .images-placeholder {
  height: 450px;
}

.images-placeholder {
  margin: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.images-placeholder .img-background {
  display: none;
}

.site-link {
  color: #fb5101;
  display: inline-block;
  vertical-align: top;
  font-weight: bold;
  text-decoration: none !important;
}

.site-link:hover {
  color: #58595b;
}

.site-list {
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: item;
  font-weight: 700;
}

.site-list li {
  font-size: 22px;
  line-height: 30px;
  padding-left: 0px;
  position: relative;
  color: #6d6e71;
  margin-bottom: 5px;
  counter-increment: item;
}

.site-list li:before {
  content: counter(item) '.';
  font-weight: 700;
  padding-right: 5px;
}

.site-list.font-light li {
  font-weight: 300;
}

.line-pattern:after {
  /* background:url(../images/bg/line-pattern.png) repeat;*/
  content: '';
  display: block;
  width: 100%;
  height: 65px;
  position: absolute;
}

.img-full {
  width: 100%;
}

.video-placeholder video {
  width: 100%;
  height: 400px;
}

.intro-block {
  padding: 25px 0;
  @media (max-width: $mobile-breakpoint) {
    padding-top: 0;
  }
}

.how-intro-block,
.how-intro-block + .grey-bg {
  position: relative;
}

.how-intro-block {
  z-index: 1;
}

.how-intro-block + .grey-bg {
  z-index: 0;
}

.cta-block {
  padding: 20px 0;
}

.cta-block .cta-logo {
  text-align: right;
  padding-right: 15px;
  border-right: 1px solid #a4a6a8;
  padding: 11px 50px 11px 0;
  display: block;
}

.cta-block .cta-text {
  margin: 0 30px;
}

.overlay-box {
  background: #00a3a0;
  max-width: 520px;
  color: #fff;
  padding: 26px 33px 15px;
  position: absolute;
  box-shadow: 15px 15px 51px rgba(0, 0, 0, 0.24);
  z-index: 1;
}

.overlay-position .container {
  position: relative;
}

.overlay-box .heading2 {
  color: #fff;
}

.overlay-box.left-top-position {
  top: 0;
  left: 15px;
}

.overlay-box.left-bottom-position {
  bottom: 0;
  left: 15px;
}

.overlay-box.right-top-position {
  top: 0;
  right: 15px;
}

.overlay-box.right-bottom-position {
  bottom: 0;
  right: 15px;
}

.overlay-box p {
  color: #fff;
}

.overlay-box p span {
  display: block;
  width: 100%;
  text-align: right;
  font-size: 16px;
}

.overlay-box a {
  display: inline-block;
  vertical-align: top;
  font-size: 21px;
  color: #fff;
  text-decoration: none;
  padding: 10px 40px 10px 12px;
  transition: background 300ms ease-in-out;
}

.overlay-box a:hover {
  background: #f05323;
}

.section-pad {
  padding-top: 60px;
  padding-bottom: 60px;
}

.section-pad-top {
  padding-top: 60px;
}

.section-pad-bottom {
  padding-bottom: 60px;
}

.res-button {
  margin-left: 20px;
}

.res-button.access-header-mobile {
  display: none;
}

.nowrap {
  white-space: nowrap;
}

.section-bg {
  background-size: cover;
  padding: 40px 0 70px;
}

.section-bg.home-bottom {
  padding-top: 0;
}

@media (min-width: 768px) {
  .section-bg.home-bottom {
    margin-top: -80px;
  }
}

.banner-content strong {
  font-size: 18px;
  color: #1b7db6;
}

.banner-content h4 {
  font-family: 'Open Sans Condensed';
  font-size: 62px;
  color: #003d65;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 15px;
}

.banner-content .site-button:hover,
.site-button.inverted-color:hover {
  border: 1px solid #e9530e;
  color: #fff !important;
  background: #e85611;
}

.boy-img {
  position: relative;
  margin: 60px 0;
}

.boy-img img {
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: -365px;
}

.boy-img:before {
  content: '';
  position: absolute;
  top: 0;
  height: 100px;
  width: 100%;
  left: 0;
  right: 0;
  background: url('../images/blue_gradient.png') no-repeat center top;
  background-size: cover;
  z-index: 0;
}

.work-box {
  padding-bottom: 20px;
}

.product-work {
  padding: 160px 0 0;
}

.product-work strong {
  color: #1b7db6;
  font-size: 16px;
  margin-bottom: 5px;
  width: 100%;
  display: block;
}

.product-work h3 {
  font-size: 24px;
  color: #003d65;
  font-weight: 700;
  text-transform: uppercase;
}

a.read-more {
  color: #e85611;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 700;
  transition: all 300ms ease-in-out;
}

a.read-more img {
  width: 11px;
  margin-left: 5px;
  transition: all 300ms;
}

a.read-more:hover {
  color: #003d65;
}

a.read-more:hover img {
  margin-left: 8px;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.bgcolor {
  background: #000;
}

.progress-circle {
  position: absolute;
  height: 300px;
  width: 300px;
  margin: 0 auto 10px;
  top: -66px;
  left: 70px;
  right: 0;
}

.progress-circle div {
  position: absolute;
  height: 300px;
  width: 300px;
  border-radius: 50%;
  border: 2px solid #08b0a0;
}

.progress-circle div span {
  position: absolute;
  font-size: 64px;
  height: 175px;
  width: 175px;
  line-height: 166px;
  left: 50%;
  top: 50%;
  text-align: center;
  border-radius: 50%;
  background-color: white;
  box-shadow: none;
  border: 2px solid #08b0a0;
  color: #08b0a0;
  font-weight: 700;
  font-weight: 700;
  transform: translateX(-50%) translateY(-50%);
}

.progress-circle .rotate {
  clip: rect(0 150px 300px 0);
}

.progress-circle .left {
  clip: rect(0 150px 300px 0);
  opacity: 1;
}

.progress-circle .right {
  clip: rect(0 150px 300px 0);
  transform: rotate(180deg);
  opacity: 0;
}

.progress-circle i {
  font-style: normal;
  clear: both;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
  color: #08b0a0;
  font-size: 16px;
}

.discover-bg p.span-bold strong {
  color: #08baa9;
  font-size: 20px;
}

.discover-bg a.read-more:hover,
.discover-bg a.read-more:hover i {
  color: #08baa9;
}

.footer-details {
  padding: 60px 0;
}

.footer-details h4 {
  font-size: 20px;
  color: #67686a;
  font-weight: 700;
  margin-top: 30px;
}

.bg-video-container {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url(../images/home-bg-img.jpg) no-repeat center;
  background-size: cover;
}

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg-video {
  max-width: none;
  width: 100%;
  height: auto;
  min-width: 0;
  object-fit: cover;
  object-position: left top;
  left: 0;
  right: auto;
  z-index: 1;
}

.blue-section {
  background: #003d65;
  color: #fff;
  h3 {
    color: #fff;
  }
  h4 {
    color: #0bb0a0;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  p {
    color: #fff;
  }
  ul {
    margin: 35px 0 35px 50px;
  }
}

.blue-section .teal {
  background: #0bb0a0;
  margin-top: 50px;
  padding: 22px 20px 22px 50px;
  display: flex;
  align-items: center;
}

.blue-section .teal img {
  margin-right: 32px;
}

.blue-section .teal p {
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.blue-section .teal a {
  color: #fff;
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .blue-section {
    padding-bottom: 0 !important;
  }

  .blue-section h4 {
    font-size: 20px;
  }

  .blue-section .teal {
    margin-top: 30px;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .blue-section .teal p {
    font-size: 16px;
  }

  .blue-section .teal img {
    max-width: 68px;
    margin-right: 23px;
  }
}

.grey-bg {
  background: #f2f5f7;
  padding: 35px 0;
  margin-top: -30px;
}

.grey-bg a {
  margin-top: 25px;
  display: inline-block;
}

.grey-bg .starting-treatment-container a {
  display: inline;
}

.video-block {
  padding: 60px 0;
}

/*Detecting Page*/
.banner-box.detecting h3 {
  margin: 20px 0;
  color: #003d65;
}

.banner-box.detecting p {
  font-size: 16px;
  color: #575656;
  font-weight: normal;
  text-transform: none;
  max-width: 1000px;
}

/*How Gamifant Works
----------------------------------------------------------------------------------*/
.how-it-work-content h3 {
  color: #003d65;
}

.how-it-work-content a {
  font-weight: 700;
  text-decoration: underline;
}

.how-it-work-content a:hover {
  color: #003d65;
}

.how-it-work-content a.site-button:hover {
  color: #e85611;
}

.how-it-work-content a.phone-number {
  text-decoration: none;
}

.how-it-work-container strong {
  color: #08b0a0;
}

.how-it-work-container {
  margin-bottom: 75px;
}

.how-it-work-content ul {
  color: #575656;
  margin-left: 20px;
  margin-bottom: 10px;
}

.how-it-work-content ul li {
  margin-top: 20px;
}

.how-it-work-content p strong {
  color: #1b7db6;
  margin: 15px 0;
}

.how-it-work-content p strong.green-text {
  color: #08baa9;
}
@media (max-width: $mobile-breakpoint) {
  .how-it-work-content {
    h3 {
      font-size: 18px;
    }
  }
}

@media (max-width: 567px) {
  .how-it-work-content {
    p {
      font-size: 14px;
    }

    h3 {
      font-size: 20px;
    }
  }
}

.padding {
  padding: 70px 0;
}

.pb-minus {
  padding-bottom: 0;
}

figure img {
  margin: 0 auto;
}

.breadcrumb-div {
  border-top: 1px solid #eaeaec;
  padding: 10px 0;
  display: none;
}

.breadcrumb-div .breadcrumb a {
  color: #575656;
  text-transform: uppercase;
}

.breadcrumb-div .breadcrumb a:hover {
  text-decoration: none;
  color: #e85611;
}

.breadcrumb-item.active {
  color: #e85611;
  font-weight: 700;
}

.breadcrumb-div .breadcrumb {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '>';
  font-weight: 400;
}

.banner-box {
  position: relative;
  z-index: 1;
}

.banner-box:before {
  display: none;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 61, 101, 0.2);
  z-index: 1;
}

.banner-box .images-placeholder {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.banner-text {
  text-transform: uppercase;
  z-index: 1;
  position: relative;
  padding: 35px 0 45px 0;
}

.banner-text strong,
.banner-text span {
  color: #1b7db6;
  margin-bottom: 8px;
  display: inline-block;
  vertical-align: top;
}

.banner-text h1 {
  color: #003d65;
  font-size: 50px;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  line-height: 0.8;
  margin-bottom: 0;
}

.banner-text h1 span {
  text-transform: lowercase;
  line-height: 0.8;
  display: inline-block;
  color: #003d65;
}

.banner-text h1 sup {
  font-size: 40px;
  top: -1em;
}

.grey-bgcolor {
  position: relative;
  background: #f2f5f7;
  margin-top: 30px;
}

.grey-bgcolor::after {
  content: '';
  background: url('../images/gamifant-accordian-bg.png') no-repeat center top;
  position: absolute;
  left: -50px;
  top: -42px;
  width: 765px;
  height: 406px;
  background-size: 90%;
}

a.site-button i {
  padding-left: 10px;
}

.blue-clr h1 {
  color: #003d65;
}

h1.orange {
  color: #e85611;
}

.blue-clr h1.orange {
  color: #e85611;
}

.blue-clr span {
  color: #1b7db6;
}

.banner-box.remove-before:before {
  background: transparent;
}

.primary-page-bg {
  background: url('../images/ifny-in-primary-banner_v4.png') no-repeat center
    top;
  background-size: cover;
}
