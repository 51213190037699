.rwe-intro-block {
  z-index: 1;
  position: relative;
}

.rwe-intro-copy {
  padding-bottom: 100px;
}

.rwe-intro-copy h3 {
  margin-bottom: 35px;
}

.rwe-intro-copy p {
  margin-bottom: 35px;
}

.rwe-banner-image {
  position: relative;
}

.rwe-banner-image img {
  position: absolute;
  left: 0;
  top: 67px;
}

.rwe .study-design .study-map {
  position: relative;
}

.rwe .study-design .container {
  position: relative;
}

.rwe .study-design .study-design-title {
  position: absolute;
  top: -80px;
  left: 0;
  background: url(../images/folder_top.png);
  background-size: contain;
  padding: 15px 44px 15px 25px;
  margin-left: 20px;
  color: #003d65;
}

.rwe .study-design .study-map .floating-box {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  padding: 30px 120px 10px;
  background-color: #f2f5f7;
  opacity: 0.75;
}

.rwe .study-design .study-map .floating-box .folder-icon-wrapper {
  position: relative;
}

.rwe .study-design .study-map .floating-box .folder-icon-wrapper figure {
  position: absolute;
  top: -50px;
  left: 0;
}

.rwe .study-design .study-map .floating-box .study-copy p {
  margin-bottom: 0;
  line-height: 26px;
}
.rwe .study-design .outcomes {
  position: relative;
  top: 20%;
}

.rwe .study-design .study-design-copy {
  padding-top: 70px;
}

.rwe .block-text {
  text-shadow: 1px 0 0 #003d65, 0 -1px 0 #003d65, 0 1px 0 #003d65,
    -1px 0 0 #003d65;
  color: #ffffff;
  font-size: 48px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 35.37px;
  text-align: left;
  text-transform: uppercase;
}

.rwe .block-text .big-number {
  text-shadow: 2px 0 2px #003d65, 0 -2px 2px #003d65, 0 2px 2px #003d65,
    -2px 0 2px #003d65;
  font-size: 77px;
  letter-spacing: 1.97px;
  line-height: normal;
}

.rwe .study-demo .small-container p {
  margin-bottom: 0;
  line-height: normal;
}

.rwe .study-demo .small-container .study-demo-top {
  padding-bottom: 30px;
  border-bottom: 1px solid #0b3c67;
}

.rwe .study-demo .small-container .study-demo-top .d-flex,
.rwe .study-demo .small-container .study-demo-bottom > .d-flex {
  align-items: center;
  justify-content: space-between;
}

.rwe .study-demo .small-container .study-demo-bottom {
  margin-top: 30px;
}

.rwe .study-demo .small-container .study-demo-bottom > .d-flex > div {
  padding: 0 30px;
  border-right: 1px solid #0b3c67;
}

.rwe
  .study-demo
  .small-container
  .study-demo-bottom
  > .d-flex
  > div:last-of-type {
  border-right: none;
}

.rwe
  .study-demo
  .small-container
  .study-demo-bottom
  > .d-flex
  > div
  > .d-flex
  > figure {
  margin-right: 15px;
}

.rwe .study-demo .small-container .study-demo-bottom > .d-flex .d-flex {
  align-items: center;
  justify-content: flex-start;
}

.rwe
  .study-demo
  .small-container
  .study-demo-bottom
  > .d-flex
  .d-flex.percents {
  margin-top: 8px;
}

.rwe .study-demo .small-container {
  max-width: 1050px;
  margin: 0 auto;
}

.rwe .study-results h1 {
  font-size: 50px;
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: 700;
  line-height: 0.8;
  margin-bottom: 38px;
}
.rwe .study-results-intro {
  font-size: 20px;
  line-height: normal;
}

.rwe .study-result-data {
  margin: 80px 0;
  @media (max-width: $mobile-breakpoint) {
    .container {
      padding: 0;
      margin-left: -15px;
      margin-right: -15px;
      width: calc(100% + 30px);
    }
  }
}

.rwe .study-result-data .grey-bg > .row {
  width: 100%;
}

.rwe .study-result-data .progress-circle div span {
  font-size: 52px;
}

.rwe .study-result-data p {
  padding: 40px 0;
  font-size: 20px;
}

.rwe .header-icon {
  padding: 40px 0;
  align-items: center;
}

.rwe .header-icon h3 {
  margin: 0;
}

.rwe .header-icon figure {
  position: relative;
  height: 66px;
  width: 66px;
  min-width: 66px;
  border: 1px solid #003d65;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 35px;
}

.rwe .header-icon figure:after {
  content: "";
  display: inline-block;
  height: 1px;
  width: 24px;
  border-top: 1px solid #003d65;
  position: absolute;
  top: 50%;
  right: -24px;
}

.rwe .scroll-arrow {
  position: relative;
}

.rwe .grey-bg {
  margin-top: 0;
}

.rwe .scroll-arrow:after {
  content: "";
  display: block;
  position: absolute;
  background: url(../images/scroll-arrow.png);
  background-size: 40px;
  background-position: center bottom;
  background-repeat: no-repeat;
  height: 55px;
  width: 55px;
  background-color: #fff;
  border-radius: 50%;
  bottom: -27px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}
.rwe .scroll-arrow.scroll-arrow-gray:after {
  background-color: #f2f5f7;
}

.rwe .svg-controls {
  display: flex;
  row-gap: 30px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.rwe .svg-controls .control {
  flex-grow: 1;
  width: 277px;
  cursor: pointer;
}

.rwe .svg-controls .control .copy {
  font-size: 16px;
  color: #575656;
  font-weight: 700;
  margin-top: 15px;
  white-space: nowrap;
}

.rwe .svg-controls .control .line-icon {
  position: relative;
  height: 6px;
  width: 80px;
  background-color: black;
}

.rwe .svg-controls .control .line-icon:after {
  content: "";
  position: absolute;
  height: 21px;
  width: 3px;
  top: -8px;
  left: 50%;
  background-color: black;
}

.rwe .svg-controls .control.fibrinogen .line-icon,
.rwe .svg-controls .control.fibrinogen .line-icon:after {
  background-color: #003d65;
}

.rwe .svg-controls .control.abs-neutrophil .line-icon,
.rwe .svg-controls .control.abs-neutrophil .line-icon:after {
  background-color: #08b0a0;
}

.rwe .svg-controls .control.platelets .line-icon,
.rwe .svg-controls .control.platelets .line-icon:after {
  background-color: #89c5df;
}

.rwe .svg-controls .control.abs-lymphocyte .line-icon,
.rwe .svg-controls .control.abs-lymphocyte .line-icon:after {
  background-color: #d7264a;
}

.rwe .svg-controls .control.alanine .line-icon,
.rwe .svg-controls .control.alanine .line-icon:after {
  background-color: #809eb2;
}

.rwe .svg-controls .control.cxcl9 .line-icon,
.rwe .svg-controls .control.cxcl9 .line-icon:after {
  background-color: #4c7793;
}

.rwe .svg-controls .control.scd25 .line-icon,
.rwe .svg-controls .control.scd25 .line-icon:after {
  background-color: #1b7db6;
}

.rwe .svg-controls .control.ferritin .line-icon,
.rwe .svg-controls .control.ferritin .line-icon:after {
  background-color: #e85611;
}

.rwe .show-all-wrapper {
  margin-top: 30px;
}

.rwe .show-all {
  position: relative;
  padding-right: 25px;
}

.rwe .show-all:after {
  content: "";
  right: 10px;
  top: 50%;
  margin-top: -3px;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 0 7px;
  border-color: #e85611 transparent transparent transparent;
  transition: transform 0.5s ease;
}

.rwe .show-all.active:after {
  transform: rotate(180deg);
}

.rwe .normalization-chart {
  margin-top: 30px;
}

.rwe .normalization-chart text {
  font-family: "Open Sans Condensed";
}

.rwe .normalization-chart.desktop-show text {
  font-weight: 700;
}

.rwe .normalization-chart.mobile-show .m-st19 {
  font-weight: 700;
}

.rwe .normalization-chart-wrapper {
  position: relative;
}

.rwe .normalization-chart-wrapper .floating-fact-box {
  position: absolute;
  bottom: 175px;
  right: 30%;
}

.rwe .normalization-chart-wrapper .floating-fact-box div {
  position: relative;
  display: none;
}

.rwe .normalization-chart-wrapper .floating-fact-box div:before {
  content: "";
  left: -17px;
  top: 10px;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 0 7px;
  border-color: black transparent transparent transparent;
  transform: rotate(-90deg);
}

.rwe .normalization-chart-wrapper .floating-fact-box p {
  margin-bottom: 0;
  font-weight: 700;
}

.rwe .normalization-chart-wrapper .floating-fact-box div.fibrinogen:before {
  border-color: #003d65 transparent transparent transparent;
}
.rwe .normalization-chart-wrapper .floating-fact-box div.abs-neutrophil:before {
  border-color: #08b0a0 transparent transparent transparent;
}
.rwe .normalization-chart-wrapper .floating-fact-box div.platelets:before {
  border-color: #89c5df transparent transparent transparent;
}
.rwe .normalization-chart-wrapper .floating-fact-box div.abs-lymphocyte:before {
  border-color: #d7264a transparent transparent transparent;
}
.rwe .normalization-chart-wrapper .floating-fact-box div.alanine:before {
  border-color: #809eb2 transparent transparent transparent;
}
.rwe .normalization-chart-wrapper .floating-fact-box div.cxcl9:before {
  border-color: #4c7793 transparent transparent transparent;
}
.rwe .normalization-chart-wrapper .floating-fact-box div.scd25:before {
  border-color: #1b7db6 transparent transparent transparent;
}
.rwe .normalization-chart-wrapper .floating-fact-box div.ferritin:before {
  border-color: #e85611 transparent transparent transparent;
}
.rwe .study-results .study-footnotes p {
  margin-bottom: 0;
  line-height: normal;
}
.rwe .study-results .study-footnotes p.special-footnote {
  line-height: 19px;
  padding-top: 9px;
  padding-bottom: 42px;
}

.rwe .rwe-progress-bar.progress-circle > div:nth-of-type(2) {
  transform: none !important;
}

.rwe .rwe-progress-bar.progress-circle.shown > div:nth-of-type(2) {
  transform: rotate(265.68deg) !important;
}
.rwe .show-all-section {
  display: none;
  padding-left: 30px;
  margin-bottom: 70px;
}

.rwe .show-all-section .d-flex {
  flex-wrap: wrap;
}

.rwe .show-all-section .d-flex .item {
  width: 25%;
  border: 1px solid #979797;
}

.rwe .show-all-section .d-flex .item:nth-of-type(2),
.rwe .show-all-section .d-flex .item:nth-of-type(3),
.rwe .show-all-section .d-flex .item:nth-of-type(4),
.rwe .show-all-section .d-flex .item:nth-of-type(6),
.rwe .show-all-section .d-flex .item:nth-of-type(7),
.rwe .show-all-section .d-flex .item:nth-of-type(8) {
  padding-left: 45px;
}

.rwe .show-all-section .d-flex .item:nth-of-type(1),
.rwe .show-all-section .d-flex .item:nth-of-type(2),
.rwe .show-all-section .d-flex .item:nth-of-type(3),
.rwe .show-all-section .d-flex .item:nth-of-type(4) {
  padding-bottom: 20px;
}

.rwe .show-all-section .d-flex .item:nth-of-type(5),
.rwe .show-all-section .d-flex .item:nth-of-type(6),
.rwe .show-all-section .d-flex .item:nth-of-type(7),
.rwe .show-all-section .d-flex .item:nth-of-type(8) {
  padding-top: 20px;
}

.rwe .show-all-section .d-flex .item:nth-of-type(1),
.rwe .show-all-section .d-flex .item:nth-of-type(5) {
  border-left: none;
}

.rwe .show-all-section .d-flex .item:nth-of-type(4),
.rwe .show-all-section .d-flex .item:nth-of-type(8) {
  border-right: none;
}

.rwe .show-all-section .d-flex .item:nth-of-type(1),
.rwe .show-all-section .d-flex .item:nth-of-type(2),
.rwe .show-all-section .d-flex .item:nth-of-type(3),
.rwe .show-all-section .d-flex .item:nth-of-type(4) {
  border-top: none;
}

.rwe .show-all-section .d-flex .item:nth-of-type(n + 5) {
  border-bottom: none;
}

.rwe .show-all-section .d-flex .item p {
  margin-bottom: 0;
}

.rwe
  .normalization-chart
  #Graph_X_and_Y_00000006691642115277040140000002838447341526844351_
  line {
  opacity: 1 !important;
}

.rwe .post-form-section .teal-text {
  margin-bottom: 42px;
}

@media (max-width: 1175px) {
  .rwe .normalization-chart-wrapper .floating-fact-box {
    bottom: 120px;
  }
}
@media (max-width: 767px) {
  .rwe .study-design .study-map .floating-box {
    position: relative;
    padding: 0;
    background-color: transparent;
    opacity: 1;
  }
  .rwe .study-design .study-map .floating-box .folder-icon-wrapper figure {
    position: relative;
    top: 0;
  }
  .rwe-intro-copy {
    padding-bottom: 80px;
  }
  .rwe .site-button.inverted-color {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  .rwe .study-demo .small-container .study-demo-top > .d-flex,
  .rwe .study-demo .small-container .study-demo-bottom > .d-flex {
    flex-direction: column;
  }
  .rwe .study-demo .small-container .study-demo-bottom > .d-flex > div {
    border-right: none;
    border-bottom: 1px solid #0b3c67;
  }
  .rwe
    .study-demo
    .small-container
    .study-demo-bottom
    > .d-flex
    > div:last-of-type {
    border-bottom: none;
  }
  .rwe .block-text {
    font-size: 38px;
  }
  .rwe .block-text .big-number {
    font-size: 62px;
  }
  .rwe .study-demo {
    padding-top: 80px;
  }
  .rwe .study-demo .small-container .study-demo-top .d-flex {
    width: 100%;
    justify-content: flex-start;
    text-align: center;
  }
  .rwe .study-demo .small-container .study-demo-top .study-children,
  .rwe .study-demo .small-container .study-demo-top .study-adults {
    text-align: left;
    margin-top: 20px;
  }
  .rwe .study-demo .small-container .study-demo-top .study-children figure {
    margin-right: 27px;
  }
  .rwe .study-demo .small-container .study-demo-bottom {
    text-align: center;
  }
  .rwe .study-demo .small-container .study-demo-bottom > .d-flex .d-flex {
    justify-content: center;
  }
  .rwe .study-demo .small-container .study-demo-bottom .block-text {
    font-size: 50px;
  }
  .rwe .study-demo .small-container .study-demo-bottom > .d-flex > div {
    padding: 28px 0;
    width: 100%;
  }
  .rwe .study-dosing {
    padding-top: 40px;
  }
  .rwe .study-results h1 {
    font-size: 28px;
  }
  .rwe .study-results-intro {
    font-size: 16px;
    line-height: normal;
  }
  .rwe .study-result-data .grey-bg.align-items-center {
    align-items: center !important;
    justify-content: center !important;
  }
  .rwe .study-result-data p {
    font-size: 18px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    text-align: center;
  }
  .rwe .study-result-data .progress-circle div span {
    font-size: 36px;
  }
  .rwe .study-result-data p {
    padding-bottom: 0;
    padding-top: 70px;
  }
  .rwe .rwe-progress-bar.progress-circle {
    top: 60px;
  }
  .rwe .svg-controls .control {
    width: 109px;
  }
  .rwe .svg-controls .control .copy {
    font-size: 12px;
    white-space: wrap;
  }
  .rwe .study-results .study-footnotes p {
    margin-bottom: 0;
    line-height: normal;
  }
  .rwe .study-results .study-footnotes {
    margin-bottom: 30px;
  }
  .rwe .post-form-section .teal-text {
    text-align: center;
    margin-bottom: 40px;
  }
  .rwe .post-form-section .site-button {
    width: 100%;
    text-align: center;
  }
  .rwe .normalization-chart-wrapper .floating-fact-box {
    bottom: 61px;
    right: 15px;
  }
  .rwe .study-results .study-footnotes p.special-footnote {
    padding-top: 4px;
    line-height: 15px;
    padding-bottom: 30px;
  }
  .rwe .show-all-section {
    padding: 0;
    text-align: center;
    margin-bottom: 30px;
  }
  .rwe .show-all-section p {
    font-size: 14px;
  }
  .rwe .show-all-section > p {
    max-width: 250px;
    margin: 0 auto;
  }
  .rwe .show-all-section .d-flex .item {
    border: none;
    border-bottom: 1px solid #979797 !important;
    width: 100%;
    padding: 20px 0 !important;
  }
  .rwe .show-all-section .d-flex .item:last-of-type {
    border-bottom: none !important;
  }
  .rwe .flipped-on-mobile {
    display: flex;
    flex-direction: column-reverse;
  }
  .rwe .banner-text h1 sup {
    top: -0.5em;
  }
}
