.isi-block {
  background: #fff;
}

.isi-block .sitepanel .panel-title {
  display: block;
  cursor: pointer;
  font-size: 0;
  height: 32px;
  position: absolute;
  right: 0;
  top: 2px;
  z-index: 2;
  width: 40px;
  border: 0;
  background: transparent;
}

.isi-block .sitepanel .btn-arrow::after {
  border: solid 3px #174775;
  border-top-color: rgb(187, 187, 187);
  border-top-style: solid;
  border-top-width: 2px;
  border-left-color: rgb(187, 187, 187);
  border-left-style: solid;
  border-left-width: 2px;
  border-left: 0;
  border-top: 0;
  position: absolute;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  width: 20px;
  height: 20px;
  top: 20px;
  left: 10px;
}

.popactive .isi-wrap.fixed .sitepanel .btn-arrow::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(45deg);
  top: 8px;
}

.isi-block .information-detail {
  padding: 20px 0 60px 0;
}

.isi-block .ftr-pdf-link a {
  color: #505061;
  font-weight: 600;
}

.isi-block .information-row1 {
  padding-top: 5px;
}

.isi-block .information-detail .site-list {
  margin-bottom: 30px;
}

.isi-block .information-detail .site-list li,
.isi-block .information-row1 .site-list li {
  font-size: 16px;
  line-height: 24px;
}

.isi-block .site-list li:before {
  top: 11px;
}

.isi-block .information-row1 h6 {
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  color: #58595b;
}

.isi-wrapper .isi-block.minimized .information-row1 h6 {
  margin-bottom: 30px;
}

.isi-block .information-row1 p {
  font-size: 16px;
  line-height: 1.3;
}

.isi-block .information-detail h6,
.isi-block .information-row1 h6 {
  font-weight: 700;
}

.isi-block .information-detail h5,
.isi-block .information-row1 h5 {
  font-weight: 600;
  font-size: 17px;
  margin: 0;
}

.isi-block .information-detail strong {
  font-weight: 600;
}

.isi-block .information-detail h4 {
  font-size: 20px;
  color: #58595b;
  font-weight: 700;
  margin-top: 30px;
}

.isi-block .information-detail .site-list li {
  font-size: 16px;
  line-height: 1.8;
  font-weight: 400;
}

.isi-block .site-list li::before {
  content: counter(item) ".";
  font-weight: 700;
  padding-right: 5px;
}

.isi-block .information-detail a {
  color: #575656;
  text-decoration: underline;
}

.isi-block .information-detail a:hover {
  color: #f05323;
}

.isi-wrap {
  transition: opacity 0.5s;
}

.isi-wrap .isi-block {
  transition: height 0.5s;
  height: 170px;
}

.isi-wrap .isi-block.minimized {
  height: 32px;
}

.isi-wrap .isi-block.expanded {
  height: auto;
}

.isi-wrap.hidden {
  opacity: 0;
}

.isi-block .information-row1 {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: -100%;
  background: #fff;
  transition: all 200ms ease-in-out;
}

/* Sticky ISI */
.isi-wrap.fixed .isi-block,
.isi-block.fixed {
  position: fixed;
  width: 100%;
  z-index: 7;
  left: 0;
  bottom: 0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
}

.isi-wrap.fixed .isi-block .information-detail {
  display: none;
}

.isi-wrap.fixed .isi-block .information-row1 {
  visibility: visible;
  opacity: 1;
  position: static;
}

.isi-wrap.fixed .isi-block .sitepanel.active ~ .information-row1 {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: -100%;
  margin: 0;
  background: #fff;
  transition: all 200ms ease-in-out;
}

.popactive .isi-wrap.fixed .isi-block .information-detail {
  max-height: calc(100vh - 220px);
  height: 600px;
  overflow: auto;
}
@media (min-width: 1200px) {
  .popactive .isi-wrap.fixed .isi-block .information-detail {
    height: auto;
    max-height: calc(100vh - 230px);
  }
  .isi-wrap.fixed .isi-block .information-detail {
    padding-top: 45px;
  }
  .isi-block .information-detail .special-border-right,
  .isi-block .information-row1 .special-border-right {
    position: relative;
    padding-right: 60px;
  }
  .isi-block .information-row1 .special-border-right::after {
    top: 28px;
    right: 0;
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    height: 100px;
    background-color: #979797;
  }
  .isi-block .information-detail .special-border-right::after {
    top: 63px;
    right: 0;
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    height: 100px;
    background-color: #979797;
  }
  .isi-block .information-detail .special-padding-left,
  .isi-block .information-row1 .special-padding-left {
    padding-left: 60px;
  }
}

.popactive .isi-wrap.fixed .information-row1 {
  display: none;
}

.popactive .isi-block .information-detail {
  padding-bottom: 0;
}

@media (min-width: 1200px) {
  .popactive .isi-wrap.fixed .isi-block .information-detail {
    height: auto;
    max-height: calc(100vh - 230px);
  }
  .isi-wrap.fixed .isi-block .information-detail {
    padding-top: 20px;
  }
  .isi-wrap.fixed .isi-block.minimized .information-row1 {
    padding-top: 0;
  }
  .isi-block .information-detail .special-border-right,
  .isi-block .information-row1 .special-border-right {
    position: relative;
    padding-right: 30px;
  }
  .isi-block .information-row1 .special-border-right::after {
    top: 0px;
    right: 0;
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    height: 100px;
    background-color: #979797;
  }
  .isi-block .information-detail .special-border-right::after {
    top: 63px;
    right: 0;
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    height: 100px;
    background-color: #979797;
  }
  .isi-block .information-detail .special-padding-left,
  .isi-block .information-row1 .special-padding-left {
    padding-left: 30px;
  }
}
