.rate-response {
  background: #174775;
  padding: 50px 30px;
  margin-top: 40px;
  h3 {
    color: #08b0a0;
  }

  p {
    color: #fff;
    &.footnote {
      font-size: 10px;
    }
  }

  ul {
    padding-left: 40px;
    margin-top: 40px;
    list-style: none;
  }

  ul li {
    color: #fff;
    line-height: 2.3;
    position: relative;
    &:before {
      width: 4px;
      height: 4px;
      background: #fff;
      content: "";
      position: absolute;
      left: -14px;
      top: 17px;
      border-radius: 50%;
    }
    &.footnote {
      font-size: 10px;
      &:before {
        content: none;
      }
    }
  }
  .order-2 > .row {
    @media (max-width: $mobile-breakpoint) {
      align-items: center !important;
      p small {
        display: inline-block;
        line-height: 1em;
      }
    }
  }
}

.rate-response .progress-circle {
  position: relative;
  height: 215px;
  width: 215px;
  margin: 0;
  top: 0;
  left: 0;
  margin: 0 auto;
  margin-top: -80px;
}

.rate-response .progress-circle div {
  position: absolute;
  height: 215px;
  width: 215px;
  border-radius: 50%;
  border: 2px solid #08b0a0;
}

.rate-response .progress-circle div span {
  position: absolute;
  font-size: 46px;
  height: 127px;
  width: 127px;
  line-height: 125px;
  left: 50%;
  top: 50%;
  text-align: center;
  border-radius: 50%;
  background-color: white;
  box-shadow: none;
  border: 2px solid #08b0a0;
  color: #08b0a0;
  font-weight: 700;
}

.rate-response .progress-circle .rotate {
  clip: rect(0 107px 215px 0);
}

.rate-response .progress-circle .left {
  clip: rect(0 107px 215px 0);
  opacity: 1;
}

.rate-response .progress-circle .right {
  clip: rect(0 107px 215px 0);
  transform: rotate(180deg);
  opacity: 0;
}

@keyframes toggle {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.gamifant-chart {
  background: #f2f5f7;
  margin: 70px 0 0;
  padding: 40px 0;
}
