[type="radio"] {
  display: none;

  +label {
    position: relative;
    padding-left: 50px;
    cursor: pointer;
    display: inline-block;
    color: #575656;
    font-size: 13px;
    margin-bottom: 15px;

    strong {
      display: block;
      font-size: 16px;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 32px;
      height: 32px;
      border: 1px solid #67686a;
      border-radius: 100%;
      background: #fff;
      transform: translateY(-50%);
    }
  }

  &:checked+label:before {
    content: "";
    background: url("../images/active_radio.svg") no-repeat center;
    background-size: 22px;
  }
}

[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.checkbox-wrapper {
  display: flex;
}

input[type="checkbox"] {
  content: "\2714";
  border: 1px solid #67686a;
  border-radius: 0;
  display: inline-block;
  width: 32px;
  height: 32px;
  vertical-align: bottom;
  color: transparent;
  left: 15px;
  line-height: 30px;
  -webkit-appearance: none;
  appearance: none;
  flex: 0 0 32px;
}

input[type="checkbox"]+label {
  margin-left: 12px;
  line-height: 1.9;
}

input[type="checkbox"]:checked {
  background: url(../images/active_check.svg) no-repeat center;
  background-size: 22px;
}

input[type="checkbox"]:disabled {
  border-color: #aaa;
}

.select2-container--default {
  .select2-selection--single {
    height: auto;
    border-color: #67686a;
    border-radius: 0;
    padding: 13px 10px;

    .select2-selection__arrow {
      b {
        top: 25px;
        left: -30px;
        border-color: $orange transparent transparent transparent;
        border-width: 7px 7px 0 7px;
      }
    }
  }

  &.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent $orange transparent;
    border-width: 0 7px 7px 7px;
  }

  .select2-search {
    display: none;
  }
}

@media (max-width: $mobile-breakpoint) {
  input[type="checkbox"] {
    width: 45px;
    height: 45px;
    flex: 0 0 45px;
    padding-left: 4px;
    line-height: 24px;
    margin-top: 7px;
  }

  input[type="checkbox"]:checked {
    background-size: 39px;
  }

  input[type="checkbox"] label {
    margin-left: 19px;
  }
}
