.symptoms-main-box.grey-bg {
  margin-top: 0;
}

.symptoms-box {
  padding-top: 20px;
}

.symptoms-box > .flex-column > div {
  flex: 1;
}

.symptoms-details {
  display: -webkit-flex;
  flex-direction: column;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.symptoms-img {
  min-height: 130px;
  display: flex;
  align-items: center;
  img {
    display: block;
    min-width: 64px;
    width: 100%;
  }
}

.advocacy-groups .accordion-databox {
  border: 1px solid #dadada;
}

.symptoms-text ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-width: 200px;
  li {
    font-weight: bold;
    color: #575656;
    padding-left: 15px;
    position: relative;
    margin-bottom: 10px;
    span {
      font-weight: 400;
      display: block;
      font-size: 15px;
    }
    &:before {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: #575656;
      top: 8px;
      left: 0;
    }
  }
}

@media (max-width: $mobile-breakpoint) {
  .symptoms-details {
    display: flex;
    flex-direction: row;
    gap: 0 20px;
    font-size: 16px;
    justify-content: flex-start;
  }

  .symptoms-img {
    padding: 0;
    img {
      width: 100px;
    }
  }
  .symptoms-text {
    ul {
      max-width: 300px;
    }
  }
}

.rare-conditions {
  .container {
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
    background: white;
    @media (max-width: $mobile-breakpoint) {
      padding-bottom: 200px;
    }
  }

  .wrapper {
    @media (min-width: $mobile-breakpoint) {
      max-width: calc(100% - 300px);
      padding-right: 50px;
    }
  }

  img {
    position: absolute;
    right: 0;
    top: 70px;
    @media (max-width: $mobile-breakpoint) {
      right: 20px;
      top: unset;
      bottom: 40px;
      width: 183px;
    }
  }

  .col-md-6 {
    max-width: 340px;
  }

  ul {
    margin-left: 30px;
  }

  ul li {
    position: relative;
    margin-top: 10px;
    padding-left: 5px;
    font-weight: bold;
  }
}
