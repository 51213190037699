/* Patient Cases page.*/
.patient-cases {

  .node--type-page .page-header a.btn.blue-scroller,
  .node--type-page .page-header a.blue-scroller.button,
  .node--type-page .page-header .node-preview-container a.blue-scroller.node-preview-backlink,
  .node-preview-container .node--type-page .page-header a.blue-scroller.node-preview-backlink {
    width: 100%;
    color: #eee;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    height: 44px;
    border-radius: 27px;
    border: 1px solid #1b7db6;
    background-color: #1b7db6;
    padding: 11px 20px;
    text-align: center;
  }

  .node--type-page .page-header a.btn.blue-scroller i,
  .node--type-page .page-header a.blue-scroller.button i,
  .node--type-page .page-header .node-preview-container a.blue-scroller.node-preview-backlink i,
  .node-preview-container .node--type-page .page-header a.blue-scroller.node-preview-backlink i {
    margin-left: 15px;
  }
}

.patient-cases .paragraph--type--row.full-width.full-width-bg-gray {
  background-color: #f2f5f7;
}

.patient-cases .paragraph--type--row .field--name-field-columns .field__item.text {
  flex: 1;
  max-width: 605px;
}

.patient-cases .paragraph--type--row .field--name-field-columns .field__item.video_image_link {
  flex: 1;
  position: relative;
}

.patient-cases .paragraph--type--row .field--name-field-columns .field__item.video_image_link .video-trigger .media {
  justify-content: flex-end;
}

.patient-cases .paragraph--type--row.full-width-bg-gray-reverse .field--name-field-columns .field__item.video_image_link .video-trigger .media {
  justify-content: flex-start;
}

.patient-cases h2 {
  color: #003d65;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
}

.patient-cases .paragraph--type--row .field--name-field-columns .field__item.video_image_link .img-fluid {
  width: 100%;
}

.patient-cases .img-fluid {
  max-width: 100%;
  height: auto;
}

.patient-cases .paragraph--type--row .field--name-field-columns .field__item.video_image_link .img-fluid {
  width: 100%;
}

.patient-cases .img-fluid {
  max-width: 100%;
  height: auto;
}

.patient-cases .img-fluid {
  max-width: 100%;
  height: auto;
}

.patient-cases p {
  font-size: 16px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 26px;
  text-align: left;
}

.patient-cases p.contact-hsd-text {
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
}

.patient-cases .paragraph--type--row .field--name-field-columns .field__item.video_image_link .media .field--name-field-media-image {
  position: relative;
}

.patient-cases .paragraph--type--row .field--name-field-columns .field__item.video_image_link .media .field--name-field-media-image:after {
  content: '';
  display: block;
  position: absolute;
  background: url(../images/play-button.png) no-repeat center;
  width: 90px;
  height: 90px;
  background-size: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.patient-cases .paragraph--type--row.full-width.full-width-bg-blue {
  background: #144575;
}

.patient-cases .paragraph--type--row.full-width.full-width-bg-blue,
.paragraph--type--row.full-width.full-width-bg-orange {
  margin-top: 0;
  margin-bottom: 0;
  padding: 40px 0;
}

.patient-cases .paragraph--type--row .field--name-field-columns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.patient-cases .paragraph--type--row.full-width.full-width-bg-blue p,
.paragraph--type--row.full-width.full-width-bg-orange p {
  color: #fff;
  font-size: 21px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  max-width: 526px;
  margin: auto;
}

.patient-cases .paragraph--type--row.full-width.full-width-bg-blue a,
.paragraph--type--row.full-width.full-width-bg-orange a {
  width: 187px;
  height: 44px;
  border-radius: 27px;
  background-color: #f36c21;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  margin-top: 20px;
}

.patient-cases .paragraph--type--row.full-width {
  margin-left: -25px;
  margin-right: -25px;
  padding: 50px 0;
}

.patient-cases .paragraph--type--row.full-width.full-width-bg-orange {
  background: #f36c21;
}

.patient-cases .paragraph--type--row.full-width.full-width-bg-orange p {
  max-width: 390px;
}

.patient-cases .paragraph--type--row.full-width.full-width-bg-orange a {
  width: 244px;
  background-color: #fff;
  color: #f36c21;
}

.patient-cases .region-isi {
  padding: 0 25px;
}

.patient-cases #block-importantsafetyinformation {
  max-width: 1130px;
  margin: auto;
  margin-top: 30px;
  padding: 0 25px;
}

.patient-cases #block-importantsafetyinformation #sticky-isi,
#block-importantsafetyinformation svg {
  display: none;
}

.patient-cases svg:not(:root) {
  overflow: hidden;
}

.patient-cases .region-isi h3 {
  color: #f36c21;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  text-transform: none;
}

.patient-cases .region-isi p {
  font-size: 16px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 28px;
  text-align: left;
  margin-bottom: 30px;
}

.patient-cases footer {
  background: #f9f9f9;
}

.patient-cases footer .region-footer {
  max-width: 1130px;
  margin: auto;
  padding: 0;
  align-items: center;
  display: flex;
  flex-direction: row;
}

@media (min-width: 768px) {

  .patient-cases .col-md-6,
  footer .region-footer>* {
    flex: 0 0 auto;
    width: 50%;
  }
}

.patient-cases footer .region-footer #block-sobifooter article.media--type-image {
  display: block;
  max-width: 174px;
  margin-top: 47px;
  margin-bottom: 35px;
}

.patient-cases footer .region-footer #block-sobifooter p {
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 21px;
  text-align: left;
  margin-bottom: 30px;
}

.patient-cases footer .region-footer #block-copyright {
  padding-left: 125px;
}

.patient-cases footer .region-footer #block-copyright ul {
  padding: 0;
  list-style: none;
  display: flex;
  margin-bottom: 58px;
}

.patient-cases footer .region-footer #block-copyright p {
  clear: both;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 21px;
  text-align: left;
}

.patient-cases footer .region-footer #block-copyright ul li {
  margin-right: 52px;
}

.patient-cases footer .region-footer #block-copyright ul li a {
  color: #333;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  text-decoration: underline;
}

.patient-cases #block-sticky-isi {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  background: #fff;
  z-index: 1049;
  transition: height 0.15s;
  border-top: 1px solid #dcdddf;
}

.patient-cases #block-sticky-isi .field--name-body {
  padding: 25px 25px 0;
  max-width: 1130px;
  margin: auto;
  position: relative;
}

.patient-cases #block-sticky-isi .field--name-body>*:not(#sticky-isi):not(svg):not(.arrow) {
  display: none;
}

.patient-cases #block-sticky-isi svg {
  position: absolute;
  right: 0;
  top: 15px;
  transform: rotate(180deg);
}

.patient-cases #block-sticky-isi.expanded #sticky-isi {
  display: none;
}

.patient-cases #block-sticky-isi.expanded .field--name-body>*:not(#sticky-isi) {
  display: inherit !important;
}

.patient-cases #block-sticky-isi.expanded {
  height: calc(100vh - 173px);
  overflow-y: scroll;
}

.patient-cases #block-sticky-isi.expanded svg {
  transform: rotate(0deg);
}

.patient-cases .site-button.pc-override {
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  margin-top: 40px;

  @media (max-width: $mobile-breakpoint) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.patient-cases #block-importantsafetyinformation a {
  font-weight: 700;
  color: #333;
  text-decoration: underline;
}

@media only screen and (min-width: 1460px) {
  .patient-cases .isi-block .information-detail .site-list {
    margin-right: -81px;
  }
}

@media only screen and (max-width: 1281px) {
  .patient-cases .paragraph--type--row.full-width.full-width-bg-gray .field--name-field-columns {
    padding-right: 0;
  }

  .patient-cases .paragraph--type--row.full-width.full-width-bg-gray .field--name-field-columns .field__item.text .paragraph {
    padding: 0 50px 0 25px;
  }

  .patient-cases .paragraph--type--row.full-width.full-width-bg-gray-reverse .field--name-field-columns {
    padding-left: 0;
  }

  .patient-cases .paragraph--type--row.full-width.full-width-bg-gray-reverse .field--name-field-columns .field__item.text .paragraph {
    padding: 0 25px 0 50px;
  }
}

@media (max-width: 1199.98px) {
  .patient-cases footer .region-footer #block-copyright {
    padding: 50px;
  }

  .patient-cases footer .region-footer #block-copyright ul li {
    margin-right: 25px;
  }
}

@media only screen and (max-width: 1181px) {
  .patient-cases header nav .region {
    margin: auto;
    padding: 0 50px 0;
  }
}

@media (max-width: 991.98px) {
  .patient-cases footer .region-footer {
    flex-direction: column !important;
  }

  .patient-cases footer .region-footer>* {
    width: 100%;
  }

  .patient-cases footer .region-footer #block-copyright {
    padding: 0;
  }
}

@media only screen and (max-width: 933px) {

  .patient-cases .paragraph--type--row .field--name-field-columns .field__item.text,
  .patient-cases .paragraph--type--row .field--name-field-columns .field__item.video_image_link {
    flex: auto;
    max-width: 100%;
  }

  .patient-cases .paragraph--type--row .field--name-field-columns .field__item.text {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .patient-cases .paragraph--type--row.full-width.full-width-bg-gray .field--name-field-columns .field__item.text,
  .patient-cases .paragraph--type--row.full-width.full-width-bg-gray-reverse .field--name-field-columns .field__item.text {
    padding-right: 25px;
    padding-left: 25px;
  }

  .patient-cases .paragraph--type--row.full-width.full-width-bg-gray .field--name-field-columns .field__item.text .paragraph,
  .patient-cases .paragraph--type--row.full-width.full-width-bg-gray-reverse .field--name-field-columns .field__item.text .paragraph {
    padding: 0;
  }

  .patient-cases #block-sticky-isi svg {
    right: 12px;
  }

  .patient-cases .region-isi {
    padding: 16px;
  }

  .patient-cases #block-sticky-isi .field--name-body {
    padding: 16px 12px;
  }

  .patient-cases #block-sticky-isi svg {
    right: 12px;
  }

  .patient-cases #block-importantsafetyinformation {
    padding: 0;
  }

  .patient-cases footer .region-footer {
    flex-direction: column !important;
  }

  .patient-cases footer .region-footer>* {
    width: 100% !important;
  }

  .patient-cases footer .region-footer #block-copyright ul {
    margin-bottom: 24px;
  }

  .patient-cases footer .region-footer #block-sobifooter p {
    margin-bottom: 20px;
  }

  .patient-cases .node--type-page .page-header .header-wrap {
    padding: 30px 0 0;
  }

  .patient-cases .node--type-page .page-header .header-wrap h1,
  .patient-cases .node--type-page .page-header .header-wrap .field--name-field-page-header-and-intro {
    max-width: 100%;
  }

  .patient-cases .node--type-page .page-header .header-wrap .field--name-field-page-header-and-intro p {
    font-size: 21px;
  }

  .patient-cases .node--type-page .page-header .header-wrap h1 {
    font-size: 38px;
    font-style: normal;
    letter-spacing: 0.71px;
    line-height: 40px;
    text-align: left;
  }

  .patient-cases h2 {
    font-size: 24px;
    letter-spacing: 0.45px;
  }
}

@media (max-width: 767.98px) {
  .patient-cases .paragraph--type--row .field--name-field-columns .field__item.video_image_link a:after {
    height: 50px;
    width: 50px;
  }

  .patient-cases #video-modal .modal-dialog {
    width: auto;
    top: 50%;
    transform: translateY(-50%);
  }

  .patient-cases #block-sticky-isi:not(.expanded) {
    max-height: 149px;
  }
}

@media (max-width: 990px) {
  .patient-cases .rate-response>.row>.col-lg-6 {
    min-height: 170px;
  }
}

@media (max-width: 767px) {
  .patient-cases .rate-response>.row>.col-lg-6 {
    min-height: 110px;
  }

  .patient-cases .rate-response>.row>.col-lg-6:nth-child(2) {
    margin-bottom: 60px;
  }

  .patient-cases .paragraph--type--row.full-width.full-width-bg-gray .field--name-field-columns {
    flex-direction: column;
    padding-right: 15px;
  }

  .patient-cases .paragraph--type--row.full-width.full-width-bg-gray-reverse .field--name-field-columns {
    flex-direction: column-reverse;
    padding-left: 15px;
  }

  .patient-cases .paragraph--type--row.full-width.full-width-bg-gray .field--name-field-columns .field__item.video_image_link,
  .patient-cases .paragraph--type--row.full-width.full-width-bg-gray-reverse .field--name-field-columns .field__item.video_image_link {
    padding-left: 25px;
    padding-right: 25px;
  }

  .patient-cases h2 {
    font-size: 20px;
  }

  .patient-cases p {
    font-size: 14px;
  }
}
