.sobi-reimbursement-banner {
  background-color: $light-grey;

  h1 {
    line-height: 56px;
    margin-bottom: 10px;
  }

  .container {
    // position: relative;
    margin-top: 100px;
  }

  .banner-text {
    .image {
      position: relative;

      img {
        position: absolute;
        top: -50%;
        right: 0;
        // transform: translateY(50%);
        max-width: 424px;
        width: 100%;
      }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    background-color: transparent;

    .container {
      margin-top: 0;
    }

    .banner-text {
      h1 {
        margin-bottom: 20px;
      }

      h3 {
        font-size: 20px;
      }

      .image {
        display: none;
      }
    }
  }

  &.hsd {
    .banner-text {
      h3 {
        max-width: 730px;
      }

      .image img {
        top: calc(50% - 200px);
      }
    }
  }
}

.intro-block.smear {
  @media (max-width: $mobile-breakpoint) {
    padding-top: 0;
  }
}

.contact-options {
  margin-bottom: 20px;

  .checkbox-container {
    margin-bottom: 10px;
  }
}

.contact-form {
  .form-group {
    margin-bottom: 30px;
  }
}
