$mobile-breakpoint: 768px;
$text-black: #575656;
$blue: #003d65;
$grey: #979797;
$light-grey: #f2f5f7;
$light-blue: #89c5df;
$teal: #0bb0a0;
$orange: #ff5100;

$primary-color: $blue;
