h3 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
}

h3.heading {
  color: #003d65;
}

h4.heading {
  font-size: 20px;
  font-weight: 700;
  color: #08b0a0;
}

h4.item-description {
  font-size: 20px;
  color: #174775;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 0;
  position: relative;
}

p {
  font-size: 16px;
  color: #575656;
  line-height: 1.8;
  margin-bottom: 15px;

  &.hanging {
    padding-left: 0.5em;

    &:first-letter {
      margin-left: -0.5em;
    }
  }
}

a {
  color: #f05323;

  &:hover {
    text-decoration: none;
    transition: all 300ms ease-in-out;
  }

  &.no-underline {
    text-decoration: none !important;
  }
}

b,
strong {
  font-weight: bold;
}

.no-bold {
  font-weight: normal !important;
}

ul {

  // This is for normal teal bullets
  &.teal-bullets {
    li::marker {
      color: $teal;
      font-size: 20px;
    }
  }
}

// Teal bullets but larger than normal
.list-style-circle {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 25px;

  li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 30px;
    line-height: 1.6;

    a {
      color: #e9530e;
      font-weight: 700;
      text-decoration: underline;
    }
  }

  li:before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #08b0a0;
    position: absolute;
    left: 0;
    top: 8px;
  }
}

.font-16 {
  font-size: 16px;
  line-height: 28px;
}

.font-18 {
  font-size: 18px;
  line-height: 24px;
}

.font-20 {
  font-size: 20px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

@media (max-width: $mobile-breakpoint) {
  .font-sm-16 {
    font-size: 16px !important;
    line-height: 26px;
  }
}

.lh-19 {
  line-height: 19px !important;
}

.horizontal-rule {
  display: flex;
  flex-direction: row;

  &:before,
  &:after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
  }

  &:before {
    margin-right: 10px;
  }

  &:after {
    margin-left: 10px;
  }
}
