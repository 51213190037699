.neutralize .accordion-databox {
  margin-top: 60px;
}

.neutralize .how-it-work-content {
  width: 80%;
  padding-left: 30px;
}

.accordion-databox {
  border: 1px solid #464646;
}

.accordion-databox .accordion-row h5 {
  margin: 0;
}

.accordion-databox .accordion-row:first-child button {
  border-top: 0;
}

.accordion-databox .accordion-row button {
  cursor: pointer;
  margin: 0;
  background: #f2f5f7;
  color: #003d65;
  position: relative;
  padding: 30px 70px 30px 30px;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  border-top: 1px solid #464646;
}

.accordion-databox .accordion-row button:focus {
  outline: 0;
}

.advocacy-groups .accordion-databox {
  border: 1px solid #464646;
}

.accordion-databox .accordion-row button:after {
  content: "";
  position: absolute;
  right: 36px;
  top: 50%;
  margin-top: -4px;
  border-top: solid 7px #e85611;
  border-left: solid 7px transparent;
  border-right: solid 7px transparent;
  z-index: 2;
}

.accordion-databox .accordion-row button.open:after {
  border-top: 0;
  border-bottom: solid 8px #e85611;
}

.accordion-data {
  display: none;
  padding: 0px 30px 20px 30px;
  border-bottom: 0;
  background: #fff;
  overflow: hidden;
}

.accordion-data p {
  padding: 0 6px;
  margin: 20px 0 0;
}

.accordion-data p:first-child {
  margin-top: 0;
}

.accordion-databox .accordion-trigger {
  border: 0;
  width: 100%;
  text-align: left;
  line-height: 1.2;
}

.accordion-databox .accordion-trigger.open {
  background: #fff;
  border-bottom: 0;
}

.accordion-databox .accordion-trigger span {
  display: block;
  font-weight: 400;
  text-transform: none;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  color: #575656;
  line-height: 1.8;
}

.accordion-databox .accordion-trigger span.inline {
  display: inline;
  font-size: 24px;
  line-height: 1.2;
}

.accordion-data .list-data ul li.cheat-up {
  margin-top: -23px !important;
}

.accordion-trigger.open p {
  display: none;
}
