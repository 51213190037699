/*Looking More-Back*/
.looking-more-block {
  padding: 46px 0 0;
}

.looking-more-block h3 {
  color: #003d65;
  margin-bottom: 25px;
}

.inner-section {
  padding: 15px 10px 60px 15px;
  background: #fff;
  border: 1px solid #67686a;
  position: relative;
  margin-right: 25px;
}

.looking-more-slider .inner-section p {
  margin-bottom: 0;
  font-size: 16px;
}

.inner-section:before {
  content: "";
  position: absolute;
  width: 32px;
  height: 64px;
  background: #fff;
  right: -31px;
  text-align: center;
  top: 40%;
  bottom: 0;
  background: url(../images/box-arrow.png);
  background-repeat: no-repeat;
}

.inner-section strong {
  color: #1b7db6;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: block;
}

.inner-section h4,
.inner-section a {
  font-size: 24px;
  font-weight: 700;
  color: #003d65;
  margin-bottom: 20px;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;
}

.inner-section p {
  line-height: 1.7;
  font-weight: 400;
  margin-bottom: 20px;
}

.arrow-btn {
  width: 24px;
  height: 24px;
  background: #e85611;
  border-radius: 100%;
  display: block;
  cursor: pointer;
  padding-bottom: 22px;
  left: 18px;
  bottom: 20px;
  position: absolute;
  transition: all 300ms ease-in-out;
}

.inner-section a:hover {
  color: #e85611;
}

.inner-section:hover .arrow-btn {
  background: #003d65;
  text-decoration: none;
  transition: all 300ms ease;
}

.arrow-btn i {
  color: #fff;
  font-size: 10px;
  text-align: center;
  margin: 0 auto;
  display: block;
  line-height: 24px;
  font-weight: bold;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.looking-more-block.padding {
  padding: 46px 0;
  background: -moz-linear-gradient(
    2deg,
    rgba(2, 62, 102, 1) 0%,
    rgba(253, 253, 254, 1) 100%
  );
  /* ff3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(2, 62, 102, 1)),
    color-stop(100%, rgba(253, 253, 254, 1))
  );
  /* safari4+,chrome */
  background: -webkit-linear-gradient(
    2deg,
    rgba(2, 62, 102, 1) 0%,
    rgba(253, 253, 254, 1) 100%
  );
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(
    2deg,
    rgba(2, 62, 102, 1) 0%,
    rgba(253, 253, 254, 1) 100%
  );
  /* opera 11.10+ */
  background: -ms-linear-gradient(
    2deg,
    rgba(2, 62, 102, 1) 0%,
    rgba(253, 253, 254, 1) 100%
  );
  /* ie10+ */
  background: linear-gradient(
    88deg,
    rgba(2, 62, 102, 1) 0%,
    rgba(253, 253, 254, 1) 100%
  );
  /* w3c */
}
