#header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 3;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  #violator.show + #wrapper #header.scroll-d {
    top: 0;
    position: fixed;
  }
}

#header.sticky {
  position: fixed;
  animation: slide-down 0.3s;
  transition: all 0.8s ease-in-out;
}

@keyframes slide-down {
  0% {
    opacity: 1;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

#header.sticky .header-top {
  display: none;
}

#header.sticky .main-navbar {
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
}

#header .logo {
  margin-left: 0;
  margin: 12px 0;
  display: inline-block;
  vertical-align: top;
  width: 152px;
}

.main-navbar {
  background: #fff;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.header-top .row {
  justify-content: flex-end;
  align-items: center;
}

.header-top {
  background: #f2f5f7;
  padding: 12px 0;
}

.header-top .dropdown .btn {
  font-size: 14px;
  color: #58595b;
  border-radius: 0;
  border: 2px solid transparent;
  padding: 3px 12px;
  box-shadow: none !important;
  background-color: transparent !important;
}

.header-top .dropdown .btn:hover {
  color: #00a3a0;
}

.header-top .dropdown.show .btn {
  color: #58595b;
  border-color: #6c757d;
}

.header-top .dropdown-toggle::after {
  display: none;
}

.header-top .patients:after {
  content: "";
  width: 2px;
  height: 14px;
  background: #8c8d8e;
  position: absolute;
  top: 0;
  margin: auto;
  right: -12px;
  bottom: 0;
}

.header-top .col-auto:last-child .patients:after {
  content: none;
}

.header-top .patients > a {
  font-size: 14px;
  color: #58595b;
  display: inline-block;
  vertical-align: top;
  text-decoration: none !important;
  padding: 0 18px 0 36px;
}

.header-top .patients > a:hover {
  color: #f05323;
}

.header-top button {
  border: 0;
  font-size: 14px;
  color: #58595b;
  padding: 0 18px 0 36px;
  background: transparent;
}

.site-navbar .btn-prescribe {
  background: #f05323;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700 !important;
  padding: 13px 15px;
  text-align: center;
  border: 1px solid #e85611;
}

.site-navbar .btn-prescribe:hover {
  background: transparent;
  color: #e85611;
}

.navbar-right {
  position: static;
}

.menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu > li {
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.menu > li:last-child:after {
  display: none !important;
}

.menu > li > a,
.menu button {
  font-size: 15px;
  color: #67686a;
  padding: 12px 8px;
  position: relative;
  text-decoration: none;
  display: block;
  font-weight: 700;
}

.menu button {
  border: 0;
  background: transparent;
  width: 100%;
  text-align: left;
}

.menu > li:hover > a {
  color: #e85611;
}

.menu > li > a.nav-trigger:after {
  content: "";
  right: 10px;
  top: 50%;
  margin-top: -3px;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 0 7px;
  border-color: #e85611 transparent transparent transparent;
}

.menu > li:hover > a.nav-trigger::after {
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent #e85611 transparent;
}

.menu > li .sub-menu {
  background: #f2f5f7;
  min-width: 222px;
  list-style: none;
  padding: 0;
}

.menu > li .sub-menu li {
}

.menu > li .sub-menu li a {
  color: #67686a;
  display: block;
  text-decoration: none;
  padding: 9px 14px;
  font-size: 15px;
  font-weight: 400;
  position: relative;
}

.menu > li .sub-menu li a:hover:after {
  content: "";
  width: 4px;
  background: #df4b14;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.menu > li .sub-menu li:last-child a {
  border-bottom: 0;
}

.menu > li .sub-menu li > ul {
  list-style: none;
}

.menu > li.active > a.nav-trigger {
  background: #f2f5f7;
}
.menu > li > a.nav-trigger {
  cursor: auto;
}

.menulines-button {
  font-size: 0;
  cursor: pointer;
  width: 35px;
  height: 30px;
  display: block;
  position: absolute;
  right: 18px;
  top: 77px;
  border: 0;
  background: transparent;
}

.menulines {
  display: inline-block;
  width: 30px;
  height: 4px;
  background: #003d65;
  position: relative;
  transition: all 550ms cubic-bezier(0.45, 1, 0.32, 1);
  opacity: 1;
  border-radius: 15px;
}

.menulines:before,
.menulines:after {
  display: inline-block;
  width: 30px;
  height: 4px;
  background: #003d65;
  position: absolute;
  left: 0;
  content: "";
  transition: all 550ms cubic-bezier(0.45, 1, 0.32, 1);
  transform-origin: 0.28571rem center;
  border-radius: 15px;
}

.menulines:after {
  top: -8px;
}

.menulines:before {
  top: 8px;
}

.menulines-button.menuopen .menulines {
  background-color: transparent;
}

.menulines-button.menuopen .menulines:before {
  transform: rotate(-50deg);
  left: 4px;
}

.menulines-button.menuopen .menulines:after {
  transform: rotate(50deg);
  left: 4px;
}
