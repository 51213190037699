.site-table {
  background: #fff;
}

.site-table .border0 {
  border: 0 !important;
}

.site-table .table th {
  text-align: center;
  background: #00a3a0;
  color: #fff;
  border: none;
  text-transform: uppercase;
  font-size: 16px;
  padding: 7px 12px;
  font-weight: normal;
}

.site-table .table td {
  font-size: 16px;
  padding: 7px 12px;
  border-right: 1px dotted #dee2e6;
}

.site-table .table td:last-child {
  border-right: 0;
}

.table th span {
  display: block;
}

.table th {
  border-top: none;
  vertical-align: middle !important;
}

.table td,
.table th {
  padding: 0.55rem;
  vertical-align: top;
  border-top: 0;
  text-align: center;
}

.table td:first-child {
  text-align: left;
  font-weight: 600;
  color: #000;
}

.site-table .table td.first-row-color {
  color: #fc5000;
}

.safety-table .table tbody tr:last-child td:nth-child(3) {
  border-right: 1px solid #c5d4d9;
}

.table-block .table-box {
  border: 1px solid #67686a;
}

.table-block .table {
  font-size: 20px;
  margin-bottom: 0;
}

.table-block .table thead {
  background: #174775;
  color: #fff;
  text-transform: uppercase;
}

.table-block .table thead th {
  text-align: center;
  border-bottom: 1px solid #dee2e6;
  border-right: none;
}

.table-block .first-table .table thead th {
  border-right: 1px solid #a2abaf;
}

.table-block .table thead th:nth-child(2n + 1) {
  text-align: left;
  padding-left: 40px;
  border-right: none;
}

.table-block .table tbody th {
  padding: 10px 20px 10px 40px;
  text-align: left;
  border-right: 1px solid #c5d4d9;
}

.table-block .table td:last-child {
  border-right: none;
}

.table-block .table td {
  text-align: center;
  vertical-align: middle;
  border-right: 1px solid #a2abaf;
  border-top: 0;
  font-size: 16px;
}

.table-block .first-table .table td {
  border-right: 1px solid #a2abaf;
}

.table-block .table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #fff;
}

.table-block .table-striped tbody tr:nth-of-type(2n + 2) {
  background-color: #ddeff5;
}

.table-details {
  padding: 35px 35px 30px 35px;
}

.table-details.fs-12 {
  font-size: 12px;
}

.table-details ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.table-details ul li {
  margin-bottom: 8px;
}

.table-details span {
  display: block;
  margin-top: 20px;
}
