/*  This section is the above the "Looking For More" Block
    with the Previous and Next buttons */
.navigation-btn {
  background: #f2f5f7;
  padding: 30px 0;
  margin-top: 40px;
}

.navigation-link a i {
  color: #e85611;
  transition: all 300ms ease-in-out;
}

.navigation-link a:hover i {
  color: #08436a;
}

.navigation-btn .breadcrumb {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
  margin-right: 10px;
}

.navigation-btn a .navigation-link h4 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
  margin: 0 5px 0 10px;
  text-transform: uppercase;
  color: #575656;
}

.navigation-btn a {
  color: #57565c;
  transition: all 300ms ease-in-out;
  outline: 0;
}

.navigation-btn a:hover {
  color: #e9530e;
}

.navigation-btn .breadcrumb li {
  font-size: 20px;
  font-weight: 400;
}

.navigation-btn a:hover .navigation-btn .breadcrumb li {
  color: #e9530e;
}
