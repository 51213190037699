.primary-color {
  color: $primary-color;
}
.blue {
  color: $blue;
}
.teal {
  color: $teal;
}
.orange {
  color: $orange;
}
.black {
  color: $text-black
}
