.target-medicated-clearance {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 50px;
    left: -700px;
    display: block;
    width: 650px;
    height: 650px;
    background-image: url(../images/pharmakonetics-art.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: 1600px) {
      left: -640px;
    }
    @media (max-width: 1400px) {
      left: -520px;
    }
    @media (max-width: 992px) {
      content: none;
    }
  }
  .text-col {
    @media(max-width: 1400px) and (min-width: 992px) {
      padding-left: 12%;
    }
  }
}
