.interstitial-modal,
.third-party-interstitial-modal {
  display: none;
  position: fixed;
  z-index: 100;
}

.modal-backdrop {
  height: 100vh;
  width: 100vw;
  background-color: #000;
  opacity: 0.2;
  position: fixed;
  z-index: 1;
}

.modal-backdrop.show {
  z-index: 1040;
}

.modal-card {
  background-color: #f2f5f7;
  max-width: 600px;
  width: 50%;
  position: fixed;
  z-index: 2;
  top: 10vh;
  left: 50%;
  transform: translateX(-50%);
}

.modal-card .modal-close-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 29px;
  width: 29px;
  border: 0;
  background: transparent;
}

.modal-card .modal-close img {
  pointer-events: none;
}

.modal-card p {
  text-align: center;
  font-weight: 700;
  color: #464646;
  width: 85%;
  margin: 90px auto 30px;
  font-size: 16px;
}

.modal-card p:last-of-type {
  margin-bottom: 30px;
}

.third-party-interstitial-modal p {
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 16px;
}

.third-party-interstitial-modal p strong {
  margin-top: 70px;
  display: block;
  font-size: 20px;
}

.modal-card .button-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;
}

.modal-card .btn {
  border: none;
  text-transform: uppercase;
  font-size: 18px;
  background-color: #f05323;
  color: white;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  width: 170px;
  padding: 12px 10px;
  border-radius: 0;
}

.modal-card .interstitial-desc {
  outline: 0;
}

[data-toggle="interstitial"] * {
  pointer-events: none;
}

@media (max-width: 768px) {
  .modal-card {
    width: 80%;
  }

  .modal-card .button-container {
    align-items: center;
    flex-direction: column;
  }

  .modal-card a {
    margin-bottom: 10px;
  }

  .third-party-interstitial-modal p {
    font-weight: 400;
    margin-bottom: 10px;
  }

  .third-party-interstitial-modal p strong {
    margin-top: 70px;
    display: block;
  }
}

#video-modal {
  background-color: rgba(0, 0, 0, 0.75);

  .modal-dialog {
    max-width: 960px;
    width: 90%;
    top: 20%;

    .modal-content {
      background: transparent;

      .btn-close {
        width: 44px;
        height: 44px;
        line-height: 44px;
        position: absolute;
        right: 0;
        top: -45px;
        text-decoration: none;
        text-align: center;
        opacity: 0.65;
        padding: 0 0 18px 10px;
        color: #FFF;
        font-style: normal;
        font-size: 28px;
        font-family: Arial, Baskerville, monospace;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }

      a {
        margin: 30px;
      }

      .modal-body {
        padding: 0;
      }
    }

    .player {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;

      iframe,
      object,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

  }
}
