.testing-primary {
  .banner-box {
    position: relative;
    .banner-text {
      position: unset;
      p {
        text-transform: none;
      }
    }
    .text {
      padding: 60px 0 60px 15px;
      @media (max-width: $mobile-breakpoint) {
        padding-top: 0;
      }
    }
    .image {
      position: unset;
      img {
        position: relative;
        right: 0;
        width: 800px;
        max-width: none;
      }
    }
  }

  .grey-bg {
    margin-top: 0;
  }
  p small {
    display: inline-block;
    line-height: 17px;
  }

  p.highlight {
    position: relative;
    margin-bottom: 40px;
    font-weight: bold;
    color: $teal;
    img {
      width: 90px;
      margin-right: 10px;
    }
    &:last-child {
      margin-bottom: 20px;
    }
    @media (max-width: $mobile-breakpoint) {
      padding-left: 100px;
      margin: 50px 0;
      font-size: 14px;
      line-height: 19px;
      img {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .cytometry-testing {
    position: relative;
    z-index: 2;
    margin-top: -60px;
    .container {
      position: relative;
    }
  }

  .study-design-title {
    position: absolute;
    top: -80px;
    left: 0;
    background: url(../images/flow-cytometry-tab.png);
    background-size: contain;
    padding: 15px 44px 15px 25px;
    margin-left: 20px;
    color: #003d65;
  }

  .scroll-arrow {
    position: relative;
    z-index: 1;
  }

  .scroll-arrow:after {
    content: '';
    display: block;
    position: absolute;
    background: url(../images/scroll-arrow.png);
    background-size: 40px;
    background-position: center bottom;
    background-repeat: no-repeat;
    height: 55px;
    width: 55px;
    background-color: #fff;
    border-radius: 50%;
    bottom: -27px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
  }
  .scroll-arrow.scroll-arrow-gray:after {
    background-color: #f2f5f7;
  }
  .blue-boxes {
    margin: 0 0 30px;
    .row {
      flex-wrap: nowrap;
      max-width: 1100px;
      justify-content: space-between;
      @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        gap: 30px;
      }
    }
    .col-12 {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 30px;
      text-align: center;
      border: 2px solid $blue;
      background-color: #fff;
      min-height: 95px;
      @media (min-width: $mobile-breakpoint) {
        max-width: 245px;
      }
    }
  }
  .testing-grid {
    max-width: 820px;
    .row {
      border-bottom: 1px solid $grey;
      margin: 0;
      &:last-child {
        border-bottom: 0;
        .col-12 {
          padding-top: 20px;
        }
        p {
          margin-bottom: 0;
        }
      }
      .col-12 {
        border-right: 1px solid $grey;
        &:first-child {
          padding-left: 0;
          padding-right: 20px;
        }
        &:nth-child(n + 2) {
          padding-left: 30px;
          padding-right: 40px;
        }
        &:last-child {
          border-right: none;
        }
      }
    }
    strong {
      color: $teal;
    }
    @media (max-width: $mobile-breakpoint) {
      .row {
        margin-left: 30px;
        margin-right: 30px;
        border-bottom: none;
      }
      .row .col-12:nth-child(n) {
        padding: 40px 0;
        border-bottom: 1px solid $grey;
        border-right: none;
      }
    }
  }
  .cta.grey-bg {
    position: relative;
    margin-bottom: 80px;
    padding: 35px 60px;
    .heading-1 {
      color: $blue;
      font-family: 'Open Sans Condensed';
      font-size: 54px;
      font-weight: bold;
    }
    img {
      position: absolute;
      top: -60px;
    }
    @media (max-width: $mobile-breakpoint) {
      padding: 20px;
      margin: 0 -15px 100px;
      .heading-1 {
        font-size: 28px;
      }
      .col-md-4 {
        min-height: 125px;
      }
      img {
        top: 20px;
        left: 50%;
        width: 175px;
        transform: translateX(-50%);
      }
    }
  }

  .organizations {
    table {
      height: 100%;
      table-layout: fixed;
      thead {
        th {
          padding: 10px 20px;
          font-size: 22px;
          color: #fff;
          text-align: center;
        }
      }
      tbody {
        td {
          min-width: 210px;
          color: $text-black;
          padding: 10px 40px;
          border-bottom: 1px solid #fff;
          border-right: 1px solid #fff;
          &:last-child {
            border-right: none;
          }
          a {
            margin: 0;
            font-weight: normal;
            word-break: break-all;
          }
        }
        tr:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
    .blue {
      thead {
        background-color: $blue;
      }
      tbody {
        background-color: #e8e9ed;
        a {
          color: $blue;
        }
      }
    }
    .orange {
      thead {
        background-color: $orange;
      }
      tbody {
        background-color: #f1e5e0;
      }
    }
    @media (max-width: $mobile-breakpoint) {
      table {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        tbody {
          td:first-child {
            min-width: 130px;
          }
          td {
            padding: 20px;
            a {
              word-break: break-all;
            }
          }
        }
      }
    }
  }

  .list-style-circle {
    li {
      margin-top: 10px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    @media (max-width: $mobile-breakpoint) {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}
