.site-button {
  font-size: 16px;
  border: 1px solid #e9530e;
  color: #fff;
  background: #e85611;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none !important;
  padding: 11px 30px;
  vertical-align: top;
  transition: all 300ms ease;
  clear: both;
  display: table;
  &:hover {
    background: transparent;
    border: 1px solid #e9530e;
    color: #e85611;
  }
}

.site-button {
  font-size: 14px;
  padding: 11px 22px;
}

.btn-download {
  position: relative;
  padding-right: 42px !important;
  &:after {
    content: "\e901";
    font-family: "icomoon" !important;
  }
}

.btn-download .site-button.btn-download:after {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -13px;
  font-size: 20px;
}

.banner-content .site-button,
.site-button.inverted-color {
  background: transparent;
  border: 1px solid #e9530e;
  color: #e85611;
}

.btn-arrow {
  position: relative;
  &:after {
    content: "";
    width: 11px;
    height: 17px;
    background-size: 11px 17px;
    display: inline-block;
    vertical-align: top;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -8px;
  }
}
