.subdue-storm {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 30px;
  position: relative;
  @media (min-width: $mobile-breakpoint) {
    li {
      position: relative;
      padding-left: 50px;
      padding-bottom: 30px;
      line-height: 1.8;
      cursor: pointer;
      margin-top: 0 !important;
      &:after {
        content: "";
        width: 2px;
        position: absolute;
        left: 16px;
        height: 100%;
        background: #33bead;
        bottom: 0;
      }
    }
    li.active {
      color: #000;
      font-weight: 600;
      a {
        color: #575656;
        font-weight: 600;
        text-decoration: none;
      }
      &:before {
        background: #33bead;
      }
    }

    li:before {
      content: "";
      width: 33px;
      height: 30px;
      position: absolute;
      left: 0;
      top: 0;
      border: 2px solid #33bead;
      border-radius: 50%;
      background: #fff;
      z-index: 1;
    }
    li:last-child:after {
      display: none;
    }
    a:hover {
      color: #000;
      text-decoration: none;
    }
  }
  @media (max-width: $mobile-breakpoint) {
    li {
      font-size: 12px;
      font-weight: normal;
      strong {
        display: block;
        color: $blue;
        font-family: "Open Sans Condensed";
        font-size: 18px;
      }
    }
  }
}

.how-gamifant-works {
  .subdue-storm {
    @media (max-width: $mobile-breakpoint) {
      list-style-type: disc;
      li {
        font-size: 14px;
      }
      li::marker {
        color: $teal;
        font-size: 18px;
      }
    }
  }
}
