.access-support {
  .enroll-content {
    @media (max-width: $mobile-breakpoint) {
      img {
        width: 300px;
      }
    }
  }

  .care-services {
    .orange-grid {
      .row {
        > .col-12 {
          position: relative;
          border-bottom: 1px solid $orange;
          padding: 30px 40px;
          gap: 40px;
          align-items: center;
          img {
            height: fit-content;
          }
          @media (max-width: $mobile-breakpoint) {
            align-items: flex-start;
          }
        }
        > .col-12:not(:last-child) {
          @media (min-width: $mobile-breakpoint) {
            border-right: 1px solid $orange;
          }
        }
        &:last-child > .col-12 {
          @media (min-width: $mobile-breakpoint) {
            border-bottom: none;
          }
        }
        &:last-child > .col-12:last-child {
          @media (max-width: $mobile-breakpoint) {
            border-bottom: none;
          }
        }
        &:first-child > .col-12:first-child:after {
          content: '';
          position: absolute;
          bottom: -5px;
          right: -5px;
          display: block;
          width: 10px;
          height: 10px;
          background-color: #fff;
          z-index: 1;
        }
      }
    }
  }

  .financial-assistance {
    .row.aos-init > .col-12 > .row {
      margin-left: 0;
      margin-right: 0;
      gap: 20px;
      @media (min-width: $mobile-breakpoint) {
        justify-content: space-between;
      }
    }
    .option {
      padding: 20px 30px;
      border-width: 2px;
      border-style: solid;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (min-width: $mobile-breakpoint) {
        max-width: 46%;
      }
      h4 {
        font-size: 20px;
        font-weight: bold;
      }
      &.teal {
        background-color: rgba(11, 176, 160, 0.1);
      }
      &.orange {
        background-color: rgba(232, 86, 17, 0.1);
      }
    }
  }

  .enroll-patient {
    .enroll-items {
      align-items: stretch;
      margin-left: 0;
      margin-right: 0;
      gap: 20px;
      @media (min-width: $mobile-breakpoint) {
        justify-content: space-between;
      }
      @media (max-width: $mobile-breakpoint) {
        gap: 80px;
      }
      > .col-md-6 {
        position: relative;
        padding: 0;
        border: 2px solid $blue;
        @media (min-width: $mobile-breakpoint) {
          max-width: 46%;
          max-height: 170px;
        }
        &:first-child:after {
          content: 'or';
          position: absolute;
          text-transform: uppercase;
          right: -13%;
          top: 40%;
          font-size: 30px;
          font-weight: bold;
          color: $blue;
          @media (max-width: $mobile-breakpoint) {
            right: 44%;
            top: 110%;
          }
        }
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
        .grey-bg {
          flex: 0 1 130px;
        }
        .contact-btn,
        .contact-info {
          flex-direction: column;
          font-size: 16px;
        }
        .contact-btn {
          padding: 20px;
          align-items: flex-start;
          .site-button {
            min-width: 130px;
            font-size: 12px;
            text-align: center;
            padding: 11px 10px;
          }
        }
        .contact-info {
          padding: 20px 30px 0 0;
          p {
            font-size: 14px;
            width: 100%;
          }
        }
        &:nth-child(2) {
          @media (max-width: $mobile-breakpoint) {
            .col-4,
            .col-sm-8 {
              height: 150px;
            }
            .col-sm-8 {
              width: 60%;
            }
            .contact-info {
              display: block;
              padding: 20px;
              p {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  .resources {
    padding: 0 30px;
    .resource {
      max-width: 610px;
      padding: 30px;
      border-bottom: 2px solid #979797;
      &:last-child {
        border-bottom: none;
      }
      img {
        max-width: 62px;
      }
      .img-col {
        align-self: center;
      }
      .text-col {
        padding: 20px;
      }
    }
  }
}
