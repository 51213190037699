.primary-page-bg {
  background-position: 28% 10% !important;
  @media (min-width: $mobile-breakpoint) {
    .how-it-work-content {
      padding: 50px 0;
    }
  }
  @media (max-width: 1023px) {
    background-position: 38% 10% !important;
  }
  @media (max-width: $mobile-breakpoint) {
    background: none;
  }
}

.mutations-disrupt.genetic-mutations {
  padding: 0;
}

@media (max-width: 1023x) {
  .mutations-disrupt {
    padding: 40px 0;
  }
}
@media (max-width: $mobile-breakpoint) {
  .mutations-disrupt img {
    margin-bottom: 20px;
  }
}

.ifny-in-primary .interactive-timeline {
  figure {
    position: absolute;
    @media (min-width: $mobile-breakpoint) and (max-width: 1550px) {
      right: calc(-9vw + 80px);
      img {
        width: 90%;
      }
    }
    @media (min-width: 1550px) {
      right: -180px;
    }
    @media (max-width: $mobile-breakpoint) {
      display: none;
    }
  }
  .download-guide {
    display: flex;
    align-items: center;
    @media (min-width: $mobile-breakpoint) {
      padding: 500px 0 0 120px;
      p {
        padding-right: 30px;
        margin-bottom: 0;
      }
    }
    .site-button {
      padding: 12px 10px;
      font-size: 12px;
      width: 115px;
      height: 43px;
      display: block;
      flex-shrink: 0;
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .container {
      position: relative;
    }
    .subdue-storm {
      background-image: url(../images/gamifant-mod-graphic-mobile.jpg);
      background-size: 140px;
      background-position: top left;
      background-repeat: no-repeat;
      padding-top: 10px;
      padding-left: 170px;
      min-height: 800px;
      li {
        max-width: 160px;
        margin-bottom: 50px;
      }
    }
  }
}

.damaging-effects {
  padding: 50px 0 100px;
}

.signs-symptoms {
  max-width: 1125px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  .table-header {
    display: flex;
    color: $blue;
    font-size: 24px;
    font-weight: bold;
    background-color: $light-grey;
    border: 1px solid #979797;
    div {
      padding: 30px 0 30px 60px;
    }
    @media (max-width: $mobile-breakpoint) {
      font-size: 20px;
      div {
        width: 100%;
        text-align: center;
        padding: 20px 0;
      }
      .col-b {
        display: none;
      }
    }
  }
  .table-scroll {
    border-left: 1px solid #979797;
    border-right: 1px solid #979797;
    border-bottom: 1px solid #979797;
    max-height: 500px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 20px;
      background-color: #fff;
      border-left: 1px solid #979797;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      background: $blue;
    }
    .table-body {
      .row {
        margin: 0;
        border-bottom: 1px solid rgba(87, 86, 86, 0.3);
        flex-wrap: nowrap;
        > div {
          padding: 20px 0 20px 60px;
          font-size: 19px;
        }
        .col-a {
          border-right: 1px solid rgba(87, 86, 86, 0.3);
        }
        ul li:not(:last-child) {
          padding-bottom: 15px;
        }
      }
      @media (max-width: $mobile-breakpoint) {
        .row {
          flex-direction: column;
          > div {
            padding: 10px 20px 10px 20px;
            font-size: 16px;
            &.col-a {
              border-right: none;
            }
            ul {
              padding-left: 20px;
            }
          }
        }
      }
    }
  }
  @media (min-width: $mobile-breakpoint) {
    .col-a {
      flex: 0 0 430px;
    }
    .col-b {
      flex: 0 1 650px;
    }
  }
}
