/* Initially the site was built off a single css file.
   Moved some of the styles into seperate SCSS files.
   Not all the styles have been converted to SCSS,
   and not all styles have been seperated properly.
*/
@import 'variables';
@import 'components/default';
@import 'components/color';
@import 'components/type';
@import 'components/button';
@import 'components/header';
@import 'components/footer';
@import 'components/accordion';
@import 'components/table';
@import 'components/modal';
@import 'components/forms';
@import 'components/navigation-btn';
@import 'components/looking-for-more';
@import 'components/subdue-storm';
@import 'components/isi';

@import 'pages/home';
@import 'pages/about-primary';
@import 'pages/diagnosing-primary';
@import 'pages/ifny-in-primary';
@import 'pages/testing-primary';
@import 'pages/discover-data';
@import 'pages/pivotal-trial';
@import 'pages/result';
@import 'pages/safety';
@import 'pages/rwe';
@import 'pages/patient-cases';
@import 'pages/dosing-flexibility';
@import 'pages/navigating-coverage';
@import 'pages/resources';
@import 'pages/sobi_reimburesement';

/* =patient css
========================================================================================*/
.time-line-img {
  box-shadow: -9px 3px 42px rgba(0, 0, 0, 0.09);
  height: 100%;
  display: inline-block;
  vertical-align: top;
}

.patient-quotes {
  color: #ff5100;
  background-color: pink;
}

.patient-quotes .user-patient {
  font-size: 16px;
  font-weight: 300;
  display: inline-block;
  vertical-align: middle;
}

.perspective-box {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 40px;
  margin-bottom: 30px;
}

a.read-more i {
  font-size: 10px;
  margin-left: 5px;
  display: inline-block;
  transition: all 300ms;
}

.ifny-tab-img-block .process-chart {
  display: none;
}

.no-bullet {
  margin-left: 0px;
  display: block;
}

.our-commitment .section-title h2 {
  color: #003d65;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.our-commitment h4.heading {
  font-size: 16px;
}

.our-commitment ul {
  max-width: 450px;
}

.phone-number {
  margin-top: 0 !important;
  text-decoration: none !important;
}

.start-form-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .start-form-container {
    flex-direction: column-reverse;
  }

  .start-form-container .site-button {
    margin-right: 0;
  }
}

.start-form-container .site-button {
  margin-right: 30px;
}

.start-form-steps-container {
  display: flex;
}

@media (max-width: 767px) {
  .start-form-steps-container {
    display: block;
  }
}

.start-form-step {
  border: 1px solid #000;
  padding: 20px 30px 80px;
  position: relative;
}

.start-form-step:first-of-type {
  margin-right: 35px;
}

.start-form-step ul {
  margin-left: 18px;
}

.start-form-step li {
  margin-bottom: 15px;
  line-height: 1.8;
}

.start-form-step .survival-patients-box {
  margin: 0;
  position: absolute;
  bottom: 20px;
}

.start-form-step .survival-patients-box a {
  text-decoration: none;
}

.start-form-step .survival-patients-img {
  width: 20%;
}

.our-priority {
  margin-bottom: 35px;
}

.our-priority .container {
  background-color: #174775;
  color: #fff;
  padding: 35px;
}

.our-priority .container p {
  color: #89c5df;
}

.our-priority ul {
  margin-left: 20px;
  width: 60%;
}

.our-priority li {
  line-height: 1.8;
  margin-bottom: 5px;
}

.our-priority h3 {
  color: #08baa9;
}

.patient-services {
  display: flex;
  margin-top: 40px;
}

.patient-services img {
  height: 110px;
  width: 110px;
}

.patient-services p {
  width: 16%;
  margin-right: 5%;
  margin-left: 2%;
}

@media (max-width: 767px) {
  .patient-services {
    display: block;
  }

  .patient-services p {
    width: 100%;
  }
}

.personalized-support {
  margin-bottom: 50px;
}

.personalized-support p {
  width: 75%;
  margin-right: 3%;
}

.personalized-support-steps-container {
  display: flex;
  margin-top: 40px;
}

@media (max-width: 767px) {
  .personalized-support-steps-container {
    display: block;
  }
}

.personalized-support-step {
  display: flex;
  align-items: center;
  width: 350px;
  margin-right: 25px;
}

.personalized-support-step img {
  width: 90px;
  height: 90px;
}

.personalized-support-step:last-of-type {
  margin: 0;
  width: 320px;
}

.support-phone h3 {
  font-size: 18px;
}

.next-steps-container {
  display: flex;
  margin: 50px 0 100px;
  justify-content: center;
}

.next-steps-content {
  padding: 30px 0 30px 25px;
  background-color: #f2f5f7;
  margin: 0 8% 0 0;
}

.next-steps-content:last-of-type {
  margin-right: 0;
}

.next-steps-content a {
  font-size: 18px;
  font-weight: 700;
}

.next-steps-content .icon-arrow {
  font-size: 14px;
  margin-left: 5px;
}

.starting-treatment-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 50px;
}

.starting-treatment-list {
  width: 45%;
  position: relative;
  margin-bottom: 50px;
}

.starting-treatment-list:first-of-type {
  padding-right: 40px;
}

.starting-treatment-list li {
  margin-left: 10px;
  margin-bottom: 20px;
  line-height: 1.8;
}

.starting-treatment-list a {
  font-weight: 700;
  text-decoration: underline;
}

.starting-treatment-list a:hover {
  color: #003d65;
}

.starting-treatment-download {
  width: 30%;
  margin-left: 100px;
}

.starting-treatment-header {
  padding-right: 40%;
  margin-bottom: 30px;
}

/* .starting-treatment-list:before {  position:absolute; left:0; top:0; font-size:36px; color:#33bead; line-height:39px; font-weight:700;  } */
/* .download-table {max-width: 1100px; margin: 0 auto;}
 .download-table td {padding: 10px 20px !important; }
 .download-table th p {font-weight: normal; margin: 0;}
 .download-table .site-button {display:block; padding: 8px 0;}
 .download-table .small-print {font-size:12px; margin: 0 10px;} */
p.small-print {
  font-size: 12px;
  margin: 8px 10px;
}

.download-table .speciality-box {
  min-height: 225px;
  position: relative;
}

.download-table .speciality-box h4 {
  margin-bottom: 0;
}

.download-table h3.speciality-heading {
  margin-top: 40px;
}

.download-table h3.speciality-heading.specialty-heading-first {
  margin-top: 0;
}

.download-table .site-button {
  margin: 0 auto;
  position: absolute;
  bottom: 20px;
}

.download-table .second-row {
  margin-top: 20px;
}

.download-table .second-row .speciality-box {
  border-top: 1px solid #d5d6d9;
}

.pivotal-trial h3 span {
  text-transform: lowercase;
}

.safety {
  margin-top: 70px;
}

.safety h4 {
  font-size: 20px;
  color: #33bead;
  font-weight: 700;
  margin-bottom: 0;
}

.safety p {
  font-weight: 700;
}

.safety-img {
  position: relative;
  text-align: center;
}

.safety-img::after {
  content: '';
  position: absolute;
  left: 10px;
  bottom: -10px;
  width: 30px;
  height: 246px;
  background: url('../images/safety-bracket.png') no-repeat center bottom;
  background-size: 96%;
  display: none;
}

.safety-img:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30px;
  height: 191px;
  background: url('../images/efficacy-bracket.png') no-repeat center bottom;
  display: none;
}

.safety ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 197px;
  margin-top: 25px;
}

.safety li {
  position: relative;
  padding-left: 25px;
  line-height: 2.4;
}

.safety li:before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  background: #174775;
  left: 0;
  top: 12px;
  border-radius: 50%;
}

.safety li:last-child:before {
  background: #08b0a0;
}

@keyframes toggle {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.primary-endpoint {
  background: url('../images/smear-primary.png') no-repeat center top;
  background-position: 50% 50%;
  background-size: cover;
}

.primary-endpoint-content {
  margin-top: 40px;
  text-align: center;
}

.primary-endpoint-content p span {
  width: 100%;
  display: inline;
}

.result-bg {
  background: url('../images/result-bg.png') no-repeat center top;
  background-size: cover;
}

.chart-bg {
  border: 1px solid #67686a;
  background: #fff;
  padding: 30px;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.3);
}

@media (min-width: 575px) {
  .safety-table-mobile-only {
    display: none;
  }
}

.safety-banner {
  position: relative;
  top: 15px;
}

.safety-table .table {
  margin-bottom: 0px;
}

.safety-table tbody+tbody {
  border-top: none;
}

/*about-primary hlh css
=============================================*/
.about-top-container {
  display: flex;
  flex: 1;
  max-width: 100%;
}

.about-top-container .how-it-work-content {
  width: 50%;
}

@media (min-width: 991px) {
  .about-top-container .how-it-work-content p {
    padding-bottom: 100px;
  }
}

.survival-patients-box {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 50px;

  @media (max-width: $mobile-breakpoint) {
    background: #fff;
    padding: 30px 0;
    width: calc(100% + 30px);
    margin-right: -15px;
    margin-left: -15px;
  }
}

.survival-patients-img {
  width: 10%;
}

.survival-patients-img img {
  width: 63px;
}

.survival-patients-text h3 {
  color: #08b0a0;
  text-transform: none;
  line-height: 1.4;
  margin-bottom: 0;

  @media (max-width: $mobile-breakpoint) {
    margin-bottom: 30px;
  }
}

.survival-patients-text h3 a {
  color: #08b0a0;
}

.survival-patients-text {
  position: relative;
  margin-left: 40px;
  width: 92%;
  max-width: 524px;
}

.survival-patients-text:before {
  content: '';
  position: absolute;
  top: 0;
  left: -20px;
  background: #08b0a0;
  width: 3px;
  height: 100%;

  @media (max-width: $mobile-breakpoint) {
    content: none;
  }
}

.about-primary-table.table-block {
  max-width: 1100px;
  margin: 0 auto;
  padding: 70px 0 100px;
}

.about-primary-table.table-block .table td {
  text-align: left;
  padding: 10px 20px 10px 40px;
}

.about-primary-table.table-block .table thead th {
  border-left: 1px solid #c5d4d9;
}

.about-primary-table.table-block .table thead td {
  border-right: 0;
  border-bottom: 1px solid #dee2e6;
}

.tbl-heading {
  text-align: center;
  background: #174775;
  padding: 15px 0;
}

.tbl-heading h3 {
  color: #08b0a0;
}

.tbl-heading p {
  margin: 0;
  color: #fff;
}

.about-primary-table.table-block .table thead {
  background: #ddeff5;
  color: #fff;
  text-transform: uppercase;
}

.about-primary-table.table-block .table thead tr {
  color: #575656;
}

.diagnosis-challenging {
  background: #174775;
  padding: 30px 0;
  margin-top: 0 !important;
}

.diagnosis-challenging figure img {
  margin-top: -180px;
}

.diagnosis-img {
  position: absolute;
  bottom: 0;
}

.diagnosis-challenging p .green-text {
  font-size: 18px;
}

.green-text {
  color: #08baa9;
  font-weight: 700;
}

.accordion-trigger span {
  text-transform: capitalize;
  font-weight: 400;
  padding-top: 5px;
  margin-bottom: 0;
}

.driver-primary {
  position: relative;
  background: #f2f5f7;
}

.driver-primary:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 8%;
  background: url('../images/driver-bg.png') no-repeat center top;
  max-width: 1586px;
  width: 100%;
  background-size: 100%;
  transform: translateY(-50%);
  padding-top: 19.6%;
}

.patient-fail {
  margin-top: 0 !important;
}

.patient-fail p strong {
  font-size: 20px;
  color: #08baa9;
}

.patient-fail .progress-circle {
  top: -117px;
}

.driver-primary.treatment-bg:before {
  content: '';
  position: absolute;
  top: 42%;
  left: 10%;
  background: url('../images/targeted-treatment.png') no-repeat center top;
  background-size: 100%;
  width: 1574px;
  height: auto;
  transform: translateY(-50%);
  padding-top: 19.6%;
}

.boy-img.mrgin-top {
  margin-top: -30px;
  position: relative;
  z-index: -1;
}

.how-it-work-content h3 span {
  text-transform: lowercase;
}

.product-name {
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
}

.product-name h4 {
  color: #575656;
  line-height: 1.5;
}

.product-name i {
  color: #e9530e;
  font-size: 40px;
  display: table;
  line-height: 1;
  transition: all 300ms;
}

.product-name i:hover {
  color: #003d65;
}

.brochure-div {
  background: #003d65;
}

.brochure-div .how-it-work-content h3 {
  color: #0dd5c2;
}

.advocacy-groups .accordion-databox .accordion-row .accordion-trigger {
  background: #f2f5f7;
  color: #575656;
  position: relative;
  font-weight: 700;
  font-size: 20px;
  padding: 25px 70px 25px 30px;
  text-transform: capitalize;
  border-color: #d0d2d4;
}

.accordion-databox {
  border: 1px solid #464646;
}

.advocacy-groups .accordion-databox .accordion-trigger.open {
  background: transparent;
}

.advocacy-groups .accordion-data {
  padding: 0px 30px 30px 30px;
}

.rare-dedication {
  background: #f2f5f7;
  padding: 55px 0;
  margin-bottom: 110px;
}

.rare-dedication figure {
  margin-bottom: -120px;
}

.rare-dedication p a {
  color: #e85611;
  text-decoration: underline;
  font-weight: 700;
}

h3.speciality-heading {
  font-size: 20px;
  color: #fff;
  background: #174775;
  padding: 22px 25px;
  text-transform: uppercase;
  margin: 0;
  width: 100%;
}

.speciality-box {
  padding: 15px;
  border: 1px solid #d5d6d9;
  border-top: 0;
  background: #fff;
  min-height: 325px;
}

.speciality-box h4 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  color: #003d65;
  margin-bottom: 20px;
  width: 100%;
}

ul.contact-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.contact-list li i {
  font-size: 20px;
  color: #fff;
  line-height: 33px;
}

ul.contact-list li span {
  background: url('../images/icon-bg.png') no-repeat left top;
  width: 41px;
  height: 37px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

ul.contact-list li a {
  font-size: 20px;
  color: #67686a;
  padding-left: 15px;
  vertical-align: middle;
}

ul.contact-list li {
  margin-bottom: 20px;
}

ul.contact-list li.highlight a {
  color: #e85611;
  font-weight: 700;
}

ul.contact-list li.highlight {
  white-space: nowrap;
}

ul.contact-list li a:hover {
  color: #174775;
}

.no-gutter {
  padding-left: 0;
  padding-right: 0;
}

.work-box-heading strong {
  text-transform: uppercase;
}

.banner-content h1 {
  font-size: 92px;
  color: #003d65;
  line-height: 1;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'Open Sans Condensed', sans-serif;
}

.discover-bg>.row {
  width: 100%;
}

.discover-content {
  max-width: 620px;
}

li.note-listing {
  margin-top: 30px;
}

.font-normal {
  font-style: normal;
}

/*Dosing Admin*/
.prepare-and-administer {}

.prepare-and-administer .speciality-heading {
  padding: 35px 0 56px;
}

.prepare-and-administer .nav-tabs {
  display: none;
  display: flex;
  justify-content: center;
  border: 0;
}

.prepare-and-administer .nav-tabs .nav-item {
  margin-bottom: 0;
  width: 14%;
  text-align: center;
  padding: 0;
  margin: 0 10px;
  margin-top: -20px;
}

.prepare-and-administer .nav-tabs .nav-link {
  border: 0;
}

.prepare-and-administer .nav-tabs .nav-item a span {
  background: #fff;
  width: 36px;
  height: 36px;
  border: 2px solid #67686a;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  margin-bottom: 18px;
}

.prepare-and-administer .nav-tabs .nav-item a {
  font-size: 20px;
  font-weight: 400;
  color: #67686a;
  padding: 0;
}

.prepare-and-administer .nav-tabs .nav-link.active span {
  background: #33bead;
  border: 2px solid transparent;
}

.prepare-and-administer .nav-tabs .nav-item.show .nav-link,
.prepare-and-administer .nav-tabs .nav-link.active {
  color: #33bead;
  font-weight: 700;
  background-color: transparent;
  border: 0;
}

.prepare-and-administer .nav-tabs .nav-link:focus,
.prepare-and-administer .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.prepare-and-administer .nav-tabs .nav-link .admin-abbrev {
  display: inline;
}

.tab-border {
  border: 2px solid #67686a;
  padding: 0px 123px 77px;
}

.tabs-content-box {
  padding-top: 50px;
}

.tabs-content-box h3 {
  color: #003d65;
}

.icon-cal {
  margin-right: 15px;
  min-width: 20%;
}

.cal-content {
  width: 80%;
}

.cal-property .form-inline .form-control {
  border-radius: 0;
  border: 1px solid;
  border-color: #979797;
  height: 62px;
  width: 100%;
  max-width: 353px;
  font-weight: 700;
  color: #0bb0a0;
  font-size: 20px;
}

.cal-content h4 {
  text-transform: uppercase;
}

.cal-property h2 {
  font-weight: 700;
  font-size: 36px;
  color: #33bead;
  margin-bottom: 0;
}

.access-ganifant {
  background: #ddeff5;
  padding: 65px 80px;
}

.radio-btn {
  width: 50%;
  float: left;
}

/*.dosing-table { border:1px solid #174775;  }*/
.attention .icon-cal {
  min-width: 11.5%;
}

@media (min-width: 768px) {
  .radio-btn {
    width: 25%;
  }

  .dosing-table table:not(.sticky-intersect) br,
  .dosing-table table:not(.sticky-intersect) br:after {
    content: ' ';
  }
}

.dosing-table .table-bordered {
  margin-bottom: 0;
  border-top: none;
}

.dosing-table thead th {
  border-bottom-width: 1px;
  background: #174775;
  line-height: 16px;
  color: #fff;
  vertical-align: middle;
  border-color: #174775;
}

.dosing-table thead th:nth-child(1) {
  color: #33bead;
}

.dosing-table thead th:nth-child(2) {
  color: #33bead;
}

.dosing-table td {
  padding: 8px 3px;
  width: 90px;
  vertical-align: middle;
}

.dosing-table tbody th {
  width: 145px;
  border-color: #999a9b;
  padding: 8px 3px;
  vertical-align: middle;
  white-space: nowrap;
}

.dosing-table tbody tr:nth-child(even) th,
.dosing-table tbody tr:nth-child(even) td {
  background: #ddeff5;
}

.dosing-table .table-hover tbody tr:hover th,
.dosing-table .table-hover tbody tr:hover td {
  background-color: #d6eae8;
}

.dosing-table.det-vol table {
  background: #fff;
}

@media (min-width: 768px) {

  .dosing-table.det-vol tr:not(.noborder).current-row td,
  .dosing-table.det-vol tr:not(.noborder) td.current-col {
    background-color: #c4ebe7 !important;
  }

  .dosing-table.det-vol tr:not(.noborder).current-row td.grey,
  .dosing-table.det-vol tr:not(.noborder) td.current-col.grey {
    background-color: #a9cfcb !important;
  }

  .dosing-table.det-vol tr:not(.noborder) th.current-col {
    background-color: #b4e2e1;
  }

  .dosing-table.det-vol tr:not(.noborder).current-row th {
    background-color: #b4e2e1;
  }

  .dosing-table.det-vol thead tr:not(.noborder) th.current-col {
    background-color: #4f899c !important;
  }

  .dosing-table .sticky-col,
  .dosing-table .sticky-intersect,
  .dosing-table .sticky-thead {
    display: none;
  }
}

.dosing-table.det-vol tr:not(.noborder) td:hover {
  background-color: #c4ebe7 !important;
}

.dosing-table.det-vol tr:not(.noborder) th:hover {
  background-color: #4f899c !important;
}

.light-clr {
  background: #ddeff5;
}

.dark-clr {
  background: #bbe6e5;
}

.light-sky {
  background: #b2d5d1;
}

.trans-clr {
  background: #b2d5d1 !important;
  opacity: 0.6;
}

.middel-div {
  color: #33bead;
  margin: 0;
}

.dosing-table .table-bordered .noborder td {
  border-color: #fff;
}

th.clr-change {
  color: #174775;
  font-weight: 700;
}

td.grey {
  background: #cccccc;
}

.dosing-table .table-bordered td {
  border-color: #999a9b;
  white-space: nowrap;
}

.border-min td,
.border-min th {
  border-bottom: 0;
}

.dilution-tab4 {
  width: 100%;
  position: relative;
  padding-left: 60px;
  margin-bottom: 50px;
}

.dilution-tab4:before {
  content: '1.';
  position: absolute;
  left: 0;
  top: 0;
  font-size: 36px;
  color: #33bead;
  line-height: 39px;
  font-weight: 700;
}

.dilution-tab4:nth-child(2):before {
  content: '2.';
}

.dilution-tab4:nth-child(3):before {
  content: '3.';
}

.second-tab .dilution-tab4:nth-child(1):before {
  content: '4.';
}

.second-tab .dilution-tab4:nth-child(2):before {
  content: '5.';
}

.tabs-content-box ul li {
  margin-bottom: 40px;
  width: 48%;
}

.tabs-content-box ul.sublisting li {
  margin-bottom: 0;
}

.tabs-content-box .list-data ul::after {
  content: none;
  display: block;
  clear: both;
}

.card {
  border: 0;
}

.gamifant-offers {
  background: #f2f5f7;
  padding: 60px 0;
}

.gamifant-offers p {
  margin: 10px 0 15px;
}

.gamifant-offers figure {
  margin-bottom: -100px;
  margin-top: -40px;
}

.smear {
  background: url('../images/smear-primary-bg.png') no-repeat center top;
  background-size: 100%;
}

.smear .tbl-heading {
  padding: 25px 210px;
}

.smear ul.sublisting {
  margin-bottom: 0;
}

.smear ul.sublisting li {
  margin: 3px 0;
}

.smear .table-box tr th {
  width: 240px;
}

.smear .about-primary-table.table-block {
  padding: 0;
}

.smear.table-bullet-align ul {
  margin-left: 20px !important;
}

.get-updates .form-control {
  font-size: 16px;
  height: 60px;
  color: #67686a;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #67686a;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.get-updates select.form-control:not([size]):not([multiple]) {
  height: 60px;
}

.get-updates .form-group {
  margin-bottom: 30px;
}

.get-intouch-img {
  position: absolute;
  right: -280px;
  bottom: -80px;
  z-index: 1;
}

.get-intouch-img img {
  margin-bottom: 45px;
  max-width: 65%;
  float: right;
}

.get-updates .d-inline-block {
  display: inline-block;
  float: none;
  width: auto;
}

.get-updates .radio-btn input[type='radio'] {
  display: block;
  position: absolute;
  opacity: 0;
  height: 37px;
  width: 37px;
  z-index: 0;
}

@media (max-width: 767px) {
  .get-updates input[type='radio']+label:before {
    width: 26px;
    height: 26px;
  }
}

/****************************/

.ifny-tab-img-block .process-chart {
  display: none;
}

.genetic-disrupt-box {
  padding: 20px 0;
}

.treatment-landscape .bg-white {
  padding: 44px;
  margin: 44px 0;
}

a.read-more:hover i {
  margin-left: 8px;
  color: #003d65;
}

.support-resource-product.owl-carousel {
  display: none;
}

/*Pivotal Trial*/
.mobile-safety-img {
  display: none;
}

.support-baby-title {
  color: #003d65;
}

.mobile-show {
  display: none;
}

.get-updates a {
  display: inline-block;
  vertical-align: top;
}

.thankyou {
  text-align: center;
  padding: 180px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.thankyou h2 {
  font-weight: 700;
  font-size: 80px;
  color: #000;
}

.thankyou figure {
  max-width: 80px;
  margin: 0 auto 20px;
}

/*New Css
=========================================================*/
.brochure-div .product-name i:hover {
  color: #fff;
  transition: all 300ms easy-in-out;
}

.list-style-circle li a:hover {
  color: #003d65;
}

/*.modal-dialog { max-width: 70%; margin:3rem auto; }
.modal-content { border-radius:0; background-color: transparent;}
.modal { margin:0 auto; }*/
.patients {
  display: flex;
}

a.clr-change {
  color: #08b0a0;
}

a.clr-change:hover {
  color: #003d65;
}

.p-max-width p {
  max-width: 50%;
}

.reference-footer {
  /* cursor:pointer; */
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mobile-open {
  display: none;
}

.cal-property [type='radio'] {
  display: block;
  position: absolute;
  opacity: 0;
  height: 37px;
  width: 37px;
  z-index: 0;
}

.cal-property [type='radio']+label {
  position: relative;
  padding-left: 47px;
  cursor: pointer;
  line-height: 37px;
  display: inline-block;
  color: #575656;
  font-size: 16px;
  margin-bottom: 15px;
}

.cal-property [type='radio']+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 37px;
  height: 37px;
  border: 1px solid #575656;
  border-radius: 100%;
  background: #fff;
}

.cal-property [type='radio']+label:after {
  content: '';
  width: 27px;
  height: 27px;
  background: #575656;
  position: absolute;
  top: 5px;
  left: 5px;
  background: #0bb0a0;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.cal-property [type='radio']:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.cal-property [type='radio']:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media (min-width: 991px) {
  .highlight-text {
    font-size: 18px;
    margin-bottom: 18px;
  }

  .home .section-bg {
    padding: 20px 0;
  }

  .home .highlight-text {
    width: 800px;
    max-width: 100%;
    text-align: left;
  }

  .home .boy-img {
    margin: 80px 0;
  }

  .home .boy-img img {
    left: 0;
    top: -385px;
    transform: scale(0.7);
  }

  .home .boy-img:before {
    height: 85px;
  }

  .home .section-bg {
    padding-top: 0;
  }

  .banner-content h1 {
    font-size: 60px;
  }

  .banner-content h4 {
    font-size: 50px;
  }

  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    /* width: 60%;
    float: right; */
  }
}

/* Violator Banner */
#violator {
  background-color: #0bb0a0;
  text-align: center;
  height: 0;
  width: 100%;
  transition: height 0.3s ease;
  z-index: 3;
}

#violator.show {
  height: 70px;
}

#violator .container {
  display: flex;
  align-items: center;
  height: 100%;
}

#violator .container .row {
  width: 100vw;
}

#violator .container .row .d-flex {
  width: 100%;
}

#violator .violator-icon {
  height: 55px;
  width: auto;
  margin-right: 20px;
}

#violator .violator-copy {
  margin-right: 15px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

#violator .violator-copy h6 {
  color: #e85611;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
}

#violator .violator-copy p {
  color: #ffffff;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

#violator .violator-button {
  background-color: #003d65;
  border: 1px solid #fff;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  min-width: 133px;
  padding: 10px 15px;
  text-align: center;
}

#violator .violator-close {
  background-image: url('../images/close-button.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 25px;
  width: 25px;
  position: absolute;
  top: -10px;
  right: 5px;
}

@media screen and (max-width: 767px) {
  #violator.show {
    height: 120px;
  }

  #violator .violator-icon,
  #violator .violator-copy {
    margin-bottom: 5px;
  }

  #violator .violator-icon {
    position: absolute;
    top: 3px;
    left: 15px;
    width: auto;
    height: 30px;
    padding-right: 15px;
  }

  #violator .violator-copy h6 {
    padding-left: 55px;
    padding-right: 45px;
  }

  #violator .violator-copy p {
    font-size: 13px;
    padding-left: 20px;
    padding-right: 20px;
  }

  #violator .violator-button {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 400px) {
  #violator .violator-copy h6 {
    padding-right: 15px;
  }
}

@media screen and (min-width: 573px) and (max-width: 767px) {
  #violator .violator-icon {
    top: 2px;
  }
}

@media screen and (min-width: 1200px) {
  #violator .violator-copy {
    margin-right: 15px;
  }

  #violator .violator-close {
    top: 0;
    right: 100px;
  }
}

/* Virtual Hub page */
.virtualhub-banner .banner-text h1 sup {
  font-size: 0.6em;
  top: -0.5em;
}

.virtualhub-intro {
  padding: 70px 0 40px;
  background-image: url('../images/virtualhub-smear-left.png');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: auto 150%;
}

.virtualhub-intro .vhi-row {
  display: flex;
}

.virtualhub-intro .vhi-icon-col {
  flex: 0 0 85px;
  align-items: center !important;
  margin-right: 10px;
  padding-right: 10px;
  position: relative;
}

.virtualhub-intro .vhi-icon-col::after {
  background: #32bead;
  content: '';
  max-height: 45px;
  height: 100%;
  width: 3px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.virtualhub-intro .vhi-icon-col img {
  height: 50px;
  width: auto;
}

.virtualhub-intro .vhi-copy-col {
  max-width: 365px;
}

.virtualhub-intro .vhi-copy-col h6 {
  color: #32bead;
  font-weight: bold;
  margin: 0;
}

.virtualhub-intro .vhi-copy-col h6 a {
  color: #32bead;
  text-decoration: underline;
}

.virtualhub-intro+.virtualhub-resources-section {
  background-image: url('../images/virtualhub-smear-right.png');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.vhr-section-title h3 {
  color: #1b4970;
}

.vhr-section-row .item {
  margin: 50px 0;
}

.vhr-section-row .item .icon-download,
.vhr-section-row .item .icon-play {
  font-size: 30px;
}

.virtualhub-questions {
  background: #003d65;
  padding: 30px 0;
}

.virtualhub-questions .vhq-col {
  align-items: center;
}

.virtualhub-questions .vhq-col::before {
  background-image: url('../images/questions-icon.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
  display: block;
  height: 110px;
  width: 130px;
  position: absolute;
  top: 0;
  left: -100px;
}

.virtualhub-questions .vhq-heading {
  color: #0dd5c2;
  text-transform: uppercase;
}

.virtualhub-questions .vhq-body,
.virtualhub-questions .vhq-body a {
  color: #ffffff;
  font-weight: bold;
}

.virtualhub-questions .vhq-body a {
  text-decoration: underline;
}

@media screen and (max-width: 1199px) {
  .virtualhub-questions .vhq-col::before {
    left: -130px;
  }
}

@media screen and (max-width: 767px) {
  .virtualhub-intro {
    padding: 30px 0 30px;
  }

  .vhr-section-row .item {
    margin: 30px 0 35px;
  }

  .vhr-section-row .item .product-name {
    margin-top: 18px;
  }

  .virtualhub-questions {
    padding: 130px 0 30px;
  }

  .virtualhub-questions .vhq-col::before {
    height: 80px;
    width: 80px;
    top: -100px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

/**
 * Sourced from "Responsive"
 */
.neutralize .how-it-work-content {
  width: 100%;
  padding-left: 0;
}

.grey-bgcolor::after {
  left: -8%;
  background-size: 70%;
}

.grey-bgcolor.neutralize {
  z-index: 1;
}

.treating-primary-box {
  background-position: 38% 0% !important;
}

@media (max-width: 1500px) {
  .banner-overlay-img {
    width: 580px;
  }

  .diagnosis-challenging {
    padding-bottom: 20px !important;
    position: relative;
  }

  .diagnosis-img {
    position: absolute;
    bottom: -20px;
  }
}

@media (min-width: 991px) and (max-height: 768px) {

  .prepare-and-administer,
  .safety-table {
    zoom: 0.8;
    -moz-transform: scale(0.8);
  }
}

@media (max-width: 1450px) {
  #footer .footer-block p {
    font-size: 18px;
  }

  #footer .footer-link li a {
    font-size: 18px;
  }

  .inner-section h4,
  .inner-section a {
    font-size: 22px;
  }

  /*Dosing
=========================================================*/
  .tab-border {
    padding: 0px 25px 77px;
  }

  .prepare-and-administer .nav-tabs .nav-item {
    width: 16%;
  }

  /*Pivotal
 ==========================================================*/
  .safety ul {
    margin-left: 0;
  }

  .safety-img::after {
    left: -22%;
    background-size: 65%;
  }

  .safety-img::before {
    right: -19%;
    background-size: 67%;
  }

  .safety-img {
    width: 100%;
    margin: 0 auto;
  }

  .safety {
    margin-top: 50px;
  }

  /*Pivotal
====================================*/

  /*how-gamifant
===============================================*/
  .neutralize .how-it-work-content {
    width: 100%;
    padding-left: 0;
  }

  .grey-bgcolor::after {
    left: -14%;
    background-size: 66%;
  }

  .grey-bgcolor.neutralize {
    z-index: 1;
  }

  /*Access
========================================================*/
  .speciality-box {
    padding: 15px;
  }

  .speciality-box ul.contact-list li a {
    font-size: 18px;
  }
}

@media (max-width: 1200px) {

  .menu>li>a,
  .menu button {
    font-size: 15px;
    padding: 12px 8px;
  }

  .menu>li>a.nav-trigger {
    padding-right: 30px;
  }

  .menu>li>a.nav-trigger::after {
    right: 10px;
  }

  .header-top .patients>a {
    font-size: 13px;
  }

  #footer .footer-block p {
    font-size: 15px;
  }

  #footer .footer-link li a {
    font-size: 15px;
  }

  #footer .footer-logo {
    width: 125px;
  }

  #footer .footer-link {
    min-height: 34px;
  }

  /*homepage*/
  /* .banner-content h1 { font-size:82px;  } */
  .progress-circle {
    top: -52px;
    left: 50px;
    height: 270px;
  }

  .progress-circle div {
    height: 270px;
    width: 270px;
  }

  .about-primary {
    background-position: 18% 10% !important;
  }

  /*about primary*/
  .diagnosis-challenging {
    padding-top: 30px;
    padding-bottom: 30px !important;
  }

  .diagnosis-img {
    bottom: -30px;
  }

  .banner-text h1 {
    font-size: 50px;
  }

  /*dosing========================================================*/

  .prepare-and-administer .tab-border {
    padding: 0px 15px 40px;
  }

  .cal-property p {
    width: 100%;
  }

  .cal-content {
    width: 100%;
  }

  .boy-img::before {
    display: none;
  }

  /*Pivotal====================================*/
  .population-section .progress-circle div {
    height: 88px;
    width: 88px;
    margin: 0 auto;
  }

  .population-section .progress-circle div span {
    font-size: 17px;
    height: 60px;
    width: 60px;
    line-height: 60px;
  }

  .population-section .progress-circle {
    height: 88px;
    width: 88px;
    margin: 0 auto;
  }

  .population-section .progress-circle .rotate {
    clip: rect(0 44px 88px 0);
  }

  .population-section .progress-circle .left {
    clip: rect(0 44px 88px 0);
    opacity: 1;
  }

  .population-section .progress-circle .right {
    clip: rect(0 44px 88px 0);
    transform: rotate(180deg);
    opacity: 0;
  }

  h4.item-description::before {
    left: -5%;
  }

  h4.item-description {
    font-size: 18px;
  }

  .population-section {
    align-items: flex-start !important;
  }

  h4.heading {
    font-size: 18px;
  }

  .safety-img::after {
    left: -22%;
    background-size: 65%;
  }

  .safety-img::before {
    right: -19%;
    background-size: 67%;
  }

  .safety-img {
    width: 100%;
    margin: 0 auto;
  }

  .navigation-btn .breadcrumb li {
    font-size: 18px;
    font-weight: 400;
  }

  .infy-primary .subdue-storm::after {
    height: 77%;
  }

  /*how-gamifant===============================================*/
  .neutralize .how-it-work-content {
    width: 100%;
    padding-left: 0;
  }

  .grey-bgcolor.neutralize::after {
    left: -15%;
    background-size: 60%;
  }

  .grey-bgcolor.neutralize {
    z-index: 1;
  }

  /*Access========================================================*/
  .speciality-box {
    padding: 15px;
  }

  .speciality-box h4 {
    font-size: 16px;
  }

  .speciality-box ul.contact-list li {
    margin-bottom: 10px;
  }

  .speciality-box ul.contact-list li a {
    font-size: 15px;
    padding-left: 7px;
  }

  /*Treatment*/
  .treatment-landscape .bg-white {
    padding: 20px 20px;
    margin: 0;
  }

  .subdue-storm::after {
    height: 95%;
  }

  /*results
=================================*/
  .rate-response h3 {
    font-size: 20px;
  }

  .rate-response h3,
  .rate-response p {
    margin-left: 30px;
  }

  .safety {
    margin-top: 0px;
  }

  .chart-bg {
    margin-top: 0;
    margin-bottom: 0;
  }

  .intro-block.section-bg.treating-primary-box {
    background-size: contain !important;
  }

  .genetic-mutations .how-it-work-content {
    padding: 40px 0;
  }

  .genetic-mutations .img-fluid {
    max-width: 550px;
    height: 376px;
  }

  .banner-text h1 sup {
    font-size: 28px;
  }

  .modal-dialog {
    max-width: 90%;
    margin: 3rem auto;
  }

  .form-group.mt-4 {
    margin: 0 !important;
    margin-bottom: 10px !important;
  }

  .form-group .mr-5.mb-5 {
    margin: 0 0 20px !important;
  }

  .brochure-div {
    padding: 30px 20px !important;
  }
}

@media (max-width: 1023px) {

  /*homepage*/
  /* .banner-content h1 { font-size:72px;  }
.banner-content h4 { font-size:65px;  } */
  .highlight-text {
    font-size: 20px;
  }

  /*======================================================*/
  .banner-text h1 {
    font-size: 40px;
  }

  .accordion-databox .accordion-row .accordion-trigger {
    font-size: 22px;
  }

  .about-primary-table.table-block {
    padding: 70px 0 50px;
  }

  .diagnosis-challenging p {
    margin-bottom: 0;
  }

  .navigation-btn .breadcrumb li {
    font-size: 14px;
  }

  .navigation-btn a .navigation-link h4 {
    font-size: 14px;
  }

  .navigation-link h4 {
    font-size: 14px;
  }

  .about-primary {
    background-position: 18% 10% !important;
  }

  /*dosing===================================================================*/
  .prepare-and-administer .tab-border {
    padding: 0px 20px 30px;
  }

  .prepare-and-administer .nav-tabs .nav-item {
    width: 20%;
    margin: 0;
    margin-top: -20px;
  }

  .prepare-and-administer .nav-tabs .nav-item a {
    font-size: 14px;
    padding: 0 8px;
    word-break: break-all;
  }

  .prepare-and-administer .speciality-heading {
    padding: 25px 0 40px;
  }

  .tabs-content-box {
    padding-top: 30px;
  }

  .tabs-content-box h3 {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px !important;
  }

  .mt-5,
  .my-5 {
    margin-top: 1rem !important;
  }

  h4.heading {
    font-size: 16px;
  }

  .access-ganifant {
    background: #ddeff5;
    padding: 30px 15px;
    margin-top: 0px;
  }

  p {
    font-size: 14px;
  }

  .dilution-tab4 {
    margin-bottom: 20px;
  }

  .padding {
    padding: 40px 0;
  }

  .gamifant-offers {
    padding: 40px 0;
  }

  .gamifant-offers figure {
    margin-bottom: 0;
    margin-top: 30px;
  }

  .smear .tbl-heading {
    padding: 25px 15px;
  }

  .smear .tbl-heading h3 {
    font-size: 18px;
  }

  .smear .table-box tr th {
    width: 164px;
    padding: 15px;
    font-size: 15px;
  }

  .smear ul.sublisting li {
    margin: 0;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .about-primary-table.table-block .table td {
    text-align: left;
    padding: 15px 15px 15px 25px;
  }

  .prepare-and-administer .tabs-content-box ul li {
    margin-bottom: 20px;
    width: 100%;
  }

  .prepare-and-administer .list-data ul li span {
    font-weight: 400;
    display: inline-block;
    font-size: 14px;
  }

  .cal-property p {
    width: 100%;
  }

  .radio-btn {
    width: 100%;
    float: left;
  }

  .cal-content {
    width: 100%;
  }

  /*Pivotal====================================*/
  .safety-img {
    width: 100%;
    margin: 0 auto;
  }

  .safety-img::before {
    right: -30px;
    background-size: 70%;
  }

  .safety-img::after {
    left: -30px;
    background-size: 61%;
  }

  .safety ul {
    margin-left: 0;
    margin-top: 15px;
  }

  .safety li {
    font-size: 14px;
  }

  .safety li::before {
    top: 9px;
  }

  .pivotal-trial {
    padding-bottom: 60px;
  }

  h4.item-description {
    padding-right: 0 !important;
    text-align: center;
  }

  h4.item-description::before {
    left: -6%;
  }

  .patient-fail .progress-circle {
    top: 0;
    margin-top: -168px;
  }

  .patient-fail {
    padding-top: 140px !important;
  }

  .neutralize .how-it-work-content {
    width: 100%;
    padding-left: 0;
  }

  .grey-bgcolor.neutralize::after {
    left: -34%;
    width: 100%;
    background-size: 50%;
    right: auto;
    z-index: -1;
  }

  .grey-bgcolor.neutralize {
    z-index: 1;
  }

  /*Access========================================================*/
  .speciality-box {
    padding: 15px;
  }

  .speciality-box h4 {
    font-size: 16px;
  }

  .speciality-box ul.contact-list li a {
    font-size: 14px;
    padding-left: 7px;
  }

  .res-button {
    margin-left: 0;
  }

  .menu>li.active>a.nav-trigger {
    background-color: transparent;
  }

  .genetic-mutations .img-fluid {
    max-width: 100%;
    height: auto;
  }

  .genetic-mutations .how-it-work-content {
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 991px) {
  .bg-video {
    height: 100%;
  }

  .menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #f2f5f7;
    z-index: 9;
    height: 392px;
    overflow: auto;
  }

  .navopen .menu {
    display: block;
  }

  .menu>li {
    display: block;
    border-bottom: 1px solid #cccfd4;
  }

  .menu>li .sub-menu {
    display: none;
    visibility: visible;
    opacity: 1;
    position: static;
    transform: translateY(0);
    padding: 0;
  }

  .menu>li>a,
  .menu button {
    background: #f2f5f7;
    color: #67686a;
    font-weight: 700;
    padding: 16px 20px;
    font-size: 16px;
  }

  .menu>li:nth-last-child(2)>a {
    border-bottom: 0;
  }

  .menu>li .sub-menu li a {
    font-size: 16px;
    background: #f2f5f7;
    padding: 0 38px 25px;
    color: #67686a;
    margin-bottom: 0px;
    font-weight: normal;
  }

  .menu>li>a.nav-trigger:after {
    right: 30px;
    transition: all 300ms ease;
  }

  .menu>li>a.nav-trigger.active:after {
    transform: rotate(-180deg);
  }

  .menu>li:hover>a.nav-trigger::after {
    border-width: 7px 7px 0 7px;
    border-color: #e85611 transparent transparent transparent;
  }

  #header .logo {
    margin-left: 0;
    margin: 12px 0;
  }

  .res-button.access-header-mobile {
    display: table;
    list-style-type: none;
    margin-left: 0;
    align-self: center;
    position: absolute;
    right: 75px;
  }

  .res-button .site-button {
    font-size: 11px;
    max-width: 141px;
    padding: 5px;
  }

  .res-button .site-button i {
    display: inline-block;
  }

  .res-button.access-header-desktop {
    display: none;
  }

  .menu>li .sub-menu li a:hover::after {
    content: none;
  }

  .menu>li .sub-menu li {
    margin: 0;
  }

  #header.sticky .menulines-button {
    top: 44px;
  }

  .menu>li .sub-menu li a:hover {
    color: #e85611;
  }

  .site-navbar .btn-prescribe {
    background: #e85611;
    color: #fff;
    border: 1px solid #e85611;
  }

  .banner-overlay-img {
    width: 500px;
  }

  .treatment-landscape .bg-white {
    margin: 10px 0;
  }

  .cta-block {
    display: none;
  }

  #footer .footer-block {
    padding: 25px 0 25px 0px;
  }

  #footer .footer-logo {
    display: table;
    width: 120px;
  }

  #footer .footer-block p {
    font-size: 12px;
  }

  #footer .footer-block {
    padding: 40px 0;
  }

  #footer .footer-link li a {
    font-size: 12px;
  }

  /*homepage css*/
  .progress-circle {
    position: relative;
    top: 0;
    margin-top: -168px;
  }

  .discover-content {
    max-width: 100%;
    padding: 20px 0;
  }

  .product-work {
    padding: 50px 0 0;
  }

  .profile-progress-bar i {
    display: none;
  }

  .banner-content h1 {
    font-size: 62px;
  }

  .banner-content h4 {
    font-size: 58px;
  }

  .discover-data {
    margin: 20px 0 20px 0;
  }

  .accordion-databox .accordion-row .accordion-trigger {
    font-size: 20px;
  }

  .boy-img img {
    left: 80px;
    top: -515px;
    transform: scale(0.7);
  }

  .bg-video-container {
    left: -160px;
  }

  /*About Primary HLH*/
  .diagnosis-challenging {
    position: relative;
    padding: 20px !important;
  }

  .diagnosis-challenging .how-it-work-content h3 {
    padding-right: 105px;
  }

  .diagnosis-challenging figure {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 264px;
  }

  .diagnosis-challenging .col-12 {
    position: static;
  }

  .diagnosis-challenging figure img {
    margin-top: 0;
  }

  .survival-patients-img {
    width: 15%;
  }

  .survival-patients-text {
    width: 85%;
  }

  .survival-patients-text h3 {
    font-size: 20px;
  }

  .how-it-work-content h3 {
    font-size: 20px;
  }

  .ifny-driver img {
    width: 100%;
  }

  .infy-primary .subdue-storm::after {
    height: 75%;
  }

  /*Access
==================================================================*/
  .speciality-box {
    padding: 20px;
    min-height: 290px;
    margin-bottom: 30px;
  }

  .speciality-box ul.contact-list li a {
    font-size: 16px;
  }

  .speciality-box ul.contact-list li {
    margin-bottom: 10px;
  }

  .next-steps-container {
    flex-direction: column;
    margin-bottom: 40px;
  }

  .next-steps-content {
    margin-bottom: 15px;
    padding: 20px 0 10px 25px;
  }

  .next-steps-content .icon-arrow {
    font-size: 10px;
  }

  /*results
	=================================*/
  .rate-response .progress-circle div {
    height: 160px;
    width: 160px;
  }

  .rate-response .progress-circle div span {
    font-size: 22px;
    height: 90px;
    width: 90px;
    line-height: 80px;
  }

  .rate-response .progress-circle {
    height: 160px;
    width: 160px;
    position: absolute;
  }

  .rate-response .progress-circle .rotate {
    clip: rect(0 80px 160px 0);
  }

  .rate-response .progress-circle .left {
    clip: rect(0 80px 160px 0);
    opacity: 1;
  }

  .rate-response .progress-circle .right {
    clip: rect(0 80px 160px 0);
    transform: rotate(180deg);
    opacity: 0;
  }

  .rate-response .rates.progress-circle {
    margin-bottom: 0;
    bottom: -60px;
    margin-top: 0;
    top: inherit;
  }

  .rate-response-text {
    margin-top: -20px;
    margin-left: 20px;
  }

  .rate-text {
    margin-left: 20px;
  }

  .rate-response ul {
    margin-top: 20px;
    margin-bottom: 50px !important;
  }

  .rate-response {
    padding: 50px 30px 20px;
  }

  .chart-bg {
    margin: 0;
  }

  .rate-response {
    padding: 50px 30px 20px;
  }

  .how-it-work-content.result-box {
    margin-top: 30px;
  }

  .how-it-work-content.result-box a {
    padding-top: 0 !important;
  }

  .table-block .table {
    font-size: 16px;
  }

  .table-block .table thead th:nth-child(2n + 1) {
    text-align: left;
    padding-left: 20px;
    border-right: none;
  }

  .table-block .table tbody th {
    padding: 20px;
    font-size: 16px;
    width: auto;
  }

  .driver-primary.treatment-bg::before {
    padding-top: 29.6%;
  }

  .thankyou h2 {
    font-size: 40px;
  }

  .genetic-mutations .how-it-work-content {
    margin: 30px 0;
  }

  .section-bg {
    padding: 50px 0;
  }

  .patients {
    display: flex !important;
    align-items: center;
  }

  .owl-carousel .owl-stage-outer {
    margin: 0 -1%;
  }

  .infusion-chart-m {
    display: block !important;
  }

  .our-priority ul {
    width: auto;
  }

  .start-form-step {
    padding: 20px 10px 80px;
    border: none;
    margin-bottom: 25px;
  }

  .start-form-step .survival-patients-text {
    margin-left: 40px;
  }

  .start-form-step .survival-patients-box .survival-patients-img {
    width: 20% !important;
  }

  .personalized-support p {
    width: auto;
  }

  .patient-services {
    margin-top: 0;
  }

  .patient-services img {
    display: block;
    margin: 0 auto;
  }

  .start-form-container .site-button {
    margin-right: 0;
  }

  .download-table .site-button.start-form-btn {
    margin: unset;
    margin-bottom: 20px;
    position: static;
  }

  .download-table .speciality-box {
    min-height: 10px;
  }

  .download-table .speciality-box .site-button {
    position: relative;
    margin-top: 20px;
    display: inline-block;
  }

  .about-top-container {
    flex-direction: column;
  }

  .about-top-container .how-it-work-content {
    width: 100%;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 14px;
  }

  .heading1 {
    font-size: 26px;
    line-height: normal;
  }

  .site-list li:before {
    top: 10px;
  }

  .header-top .patients>a {
    font-size: 9px;
    padding: 3px 7px;
  }

  .header-top .row {
    justify-content: center;
  }

  .header-top .patients::after {
    width: 1px;
    height: 8px;
    margin: auto;
    right: -1px;
    bottom: 0;
  }

  #footer .footer-link {
    min-height: 0px;
  }

  /*homepage css*/
  #wrapper {
    padding-top: 140px;
  }

  .discover-content {
    padding: 20px 30px;
  }

  .discover-data h2,
  .banner-content h1 {
    font-size: 52px;
  }

  .banner-content h4 {
    font-size: 48px;
  }

  .work-box {
    padding-bottom: 40px;
  }

  .boy-img img {
    top: -185px;
    max-width: 330px;
    transform: none;
  }

  .discover-data {
    margin: 0 0 20px;
  }

  .discover-data .row>div {
    margin-bottom: 20px;
  }

  .looking-more-block {
    padding: 30px 0 0;
  }

  /*About Primary HLH*/

  .diagnosis-challenging {
    padding-bottom: 0 !important;
  }

  .diagnosis-challenging figure {
    position: static;
    right: 0;
    top: -60px;
    max-width: 200px;
  }

  .diagnosis-challenging .how-it-work-content p {
    margin-bottom: 10px;
  }

  .accordion-databox .accordion-row .accordion-trigger {
    font-size: 20px;
  }

  .table-block .table {
    font-size: 18px;
  }

  .banner-text h1 {
    font-size: 44px;
  }

  .accordion-databox .accordion-row .accordion-trigger {
    padding: 20px 70px 20px 30px;
  }

  .further-testing {
    padding: 30px 0;
  }

  .driver-primary {
    padding: 30px 0;
    margin-top: 30px;
  }

  .driver-primary::before {
    top: 0;
    left: 65%;
  }

  .navigation-btn .breadcrumb li {
    font-size: 16px;
  }

  .navigation-link h4 {
    font-size: 16px;
  }

  .navigation-link {
    float: left !important;
  }

  .ifny-tab-img-block .process-chart {
    display: block;
  }

  .ifny-tab-img-block .ifny-tab-img {
    display: none;
  }

  .inner-section h4,
  .inner-section a {
    font-size: 20px;
  }

  .inner-section strong {
    margin-bottom: 2px;
  }

  .looking-more-block h3 {
    font-size: 20px;
  }

  .treating-primary-box {
    background: none !important;
  }

  .driver-primary.treatment-bg::before {
    margin-top: -120px;
  }

  .treatment-landscape {
    padding: 0 0 90px 0;
  }

  .driver-primary .how-it-work-content {
    padding-top: 0;
  }

  /*dosing===================================================================*/
  .prepare-and-administer .tab-border {
    padding: 0px 20px 30px;
  }

  .prepare-and-administer .nav-tabs .nav-item {
    width: 20%;
    margin: 0;
    margin-top: -20px;
  }

  .prepare-and-administer .nav-tabs .nav-item a {
    font-size: 16px;
    padding: 0 8px;
    word-break: break-all;
  }

  .prepare-and-administer .speciality-heading {
    padding: 25px 0 40px;
  }

  .prepare-and-administer .nav-tabs .nav-link .admin-abbrev {
    display: none;
  }

  .tabs-content-box {
    padding-top: 30px;
  }

  .tabs-content-box h3 {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px !important;
  }

  .cal-content h4.heading {
    margin-bottom: 0 !important;
  }

  .mt-5,
  .my-5 {
    margin-top: 2rem !important;
  }

  [type='radio']:checked+label,
  [type='radio']:not(:checked)+label {
    padding-left: 32px;
    line-height: 23px;
    font-size: 14px;
    margin-bottom: 15px;
  }

  [type='radio']:checked+label::before,
  [type='radio']:not(:checked)+label::before {
    width: 26px;
    height: 26px;
    background-size: 18px;
    border-color: #eaeaec;
  }

  [type='radio']:checked+label::after,
  [type='radio']:not(:checked)+label::after {
    width: 17px;
    height: 17px;
    top: 4px;
    left: 4px;
  }

  h4.heading {
    font-size: 16px;
  }

  .access-ganifant {
    background: #ddeff5;
    padding: 30px 15px;
    margin-top: 30px;
  }

  p {
    font-size: 14px;
  }

  .dilution-tab4 {
    margin-bottom: 20px;
  }

  .padding {
    padding: 40px 0;
  }

  .gamifant-offers {
    padding: 40px 0;
  }

  .gamifant-offers figure {
    margin-bottom: 0;
    margin-top: 30px;
  }

  .smear .tbl-heading {
    padding: 25px 15px;
  }

  .smear .tbl-heading h3 {
    font-size: 18px;
  }

  .smear .table-box tr th {
    width: 164px;
    padding: 15px;
    font-size: 15px;
  }

  .smear ul.sublisting li {
    margin: 0;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .about-primary-table.table-block .table td {
    text-align: left;
    padding: 15px 15px 15px 25px;
  }

  .prepare-and-administer .tabs-content-box ul li {
    margin-bottom: 20px;
    width: 100%;
  }

  .prepare-and-administer .list-data ul li span {
    font-weight: 400;
    display: inline-block;
    font-size: 14px;
  }

  /*Support-Resource=================================*/
  .mobi-hide {
    display: none;
  }

  .support-resource-product.owl-carousel {
    display: block;
    padding-bottom: 60px;
  }

  .owl-dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .support-resource-product.owl-carousel .owl-dot {
    width: 15px;
    height: 15px;
    border: 1px solid #08b0a0;
    border-radius: 50%;
    margin: 0 2px;
  }

  .support-resource-product.owl-carousel .owl-dot.active {
    background: #08b0a0;
  }

  .product-name i {
    font-size: 22px;
  }

  .support-resource-product.owl-carousel .owl-item img {
    display: block;
    width: auto;
  }

  .accordion-data .list-data ul li.cheat-up {
    margin-top: 0 !important;
  }

  .boy-img::before {
    display: none;
  }

  .survival-patients-box .survival-patients-img {
    display: block;
    width: 14% !important;
  }

  .support-products {
    background: #f2f5f7;
    border-top: 1px solid #67686a;
    border-bottom: 1px solid #67686a;
  }

  .brochure-div .how-it-work-content h3 {
    margin-top: 30px;
    margin-bottom: -10px;
  }

  .advocacy-groups .accordion-data {
    padding: 10px;
    padding-bottom: 40px;
  }

  .advocacy-groups .accordion-databox .accordion-row .accordion-trigger {
    font-size: 14px;
    padding: 10px 20px 10px 10px;
  }

  .accordion-databox .accordion-row .accordion-trigger::after {
    right: 10px;
  }

  a.read-more {
    font-size: 12px;
  }

  .advocacy-groups h4.heading {
    font-size: 14px;
    line-height: 1.5;
    padding-bottom: 10px !important;
  }

  .rare-dedication {
    margin-top: 40px;
    margin-bottom: 0;
  }

  .rare-dedication figure {
    margin-bottom: 20px;
    margin-top: -90px;
  }

  .rare-dedication .how-it-work-content p {
    font-size: 14px;
    margin-bottom: 0;
  }

  /*pivotal=====================================================*/
  .pivotal-trial .safety-hide {
    display: none;
  }

  .mobile-safety-img {
    display: block;
    width: 440px;
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 30px;
    max-width: 100%;
  }

  .population-section .progress-circle {
    margin: 0 auto;
  }

  .navigation-btn {
    display: none;
  }

  h4.item-description::before {
    left: -12%;
  }

  .primary-endpoint-content {
    margin-top: 10px;
  }

  /*How-gamifant-works=====================================================*/
  .grey-bgcolor::after {
    width: 100%;
    background-size: 50%;
    right: -20%;
    left: auto;
    z-index: -1;
  }

  .grey-bgcolor.neutralize {
    z-index: 1;
  }

  /*Access*/
  .banner-text h1 sup {
    font-size: 20px;
  }

  h3.speciality-heading {
    font-size: 18px;
    padding: 20px 20px;
  }

  .speciality-box {
    padding: 20px 20px;
    min-height: auto;
    margin-bottom: 20px;
  }

  .speciality-box h4 {
    font-size: 18px;
  }

  .speciality-box ul.contact-list li a {
    font-size: 18px;
  }

  .speciality-box ul.contact-list li {
    margin-bottom: 10px;
  }

  .speciality {
    padding-bottom: 0;
  }

  /*results
=================================*/
  #footer .footer-block {
    padding: 40px 0 0;
  }

  .chart-bg {
    margin: 0;
  }

  .rate-response {
    padding: 50px 30px 20px;
  }

  .col-top {
    padding-top: 50px;
  }

  .about-primary {
    background-position: 50% 20% !important;
  }

  .survival-patients-text {
    margin-top: 0;
  }

  #footer .footer-link {
    margin-top: 0;
    margin-bottom: 0;
  }

  .popactive .isi-wrap.fixed .isi-block .information-detail {
    max-height: 400px;
  }

  .grey-bgcolor.neutralize::after {
    width: 100%;
    height: 296px;
    background-size: 260px;
    left: auto;
    right: -23%;
  }

  .neutralize .how-it-work-content {
    margin-top: 80px;
  }

  .support-baby-img {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }

  .support-baby-img img {
    order: 1;
    margin-top: -30px;
    margin-bottom: 20px;
  }

  .support-baby-img h3 {
    order: 2;
  }

  .get-intouch-img {
    display: none;
  }

  .section-bg {
    padding: 40px 0;
  }

  .patients.d-none {
    display: none !important;
  }

  .owl-carousel .owl-stage-outer {
    margin: 0 -1%;
  }

  .inner-section {
    margin-right: 18px;
  }

  .get-updates .form-group {
    margin-bottom: 15px;
  }

  .get-updates .mr-5,
  .get-updates .mb-4 {
    margin-right: 0 !important;
  }

  .p-max-width p {
    max-width: 100%;
  }

  .table-block .table thead th:nth-child(3) {
    display: none;
  }

  .table-block .table thead th:nth-child(4) {
    display: none;
  }

  .safety-table .table,
  .safety-table thead,
  .safety-table tbody {
    display: block;
  }

  .safety-table tr {
    display: flex;
    flex-wrap: wrap;
  }

  .safety-table tbody tr:last-child th:nth-of-type(2) {
    display: none;
  }

  .safety-table.table-block .table-striped tbody tr:nth-of-type(2n + 2) {
    background-color: transparent;
  }

  .safety-table td,
  .safety-table th {
    width: 50% !important;
    border-right: 0 !important;
    border-left: 1px solid #ddd;
  }

  .table-block .first-table .table td {
    border-right: none;
  }

  .safety-table .table td {
    font-size: 14px !important;
    padding: 10px !important;
  }

  .safety-table .table th {
    font-size: 14px;
  }

  .safety-table .table tbody th {
    padding: 10px !important;
  }

  .safety-table .table tbody tr:last-child td:nth-child(3) {
    border-right: 1px solid #c5d4d9;
  }

  .table-details {
    padding: 40px 10px 15px;
    font-size: 0.8rem;
  }

  .table-details ul li {
    margin: 0;
  }

  .result-bg .list-style-circle.ml-4.mb-2 {
    margin: 0 !important;
  }

  .list-style-circle li {
    margin-bottom: 10px;
  }

  .safety-table {
    margin-bottom: 10px;
  }

  .dosing-img-mob img {
    width: 200px;
    margin-bottom: 20px;
  }

  .no-padding {
    padding: 0;
  }

  .no-padding-right {
    padding-right: 0px;
  }

  /*.owl-carousel .owl-stage-outer {margin: 0 -75%;}*/
  #header .logo {
    width: 100px;
  }

  .menulines-button {
    top: 63px;
  }

  .menulines-button:focus {
    outline: none;
  }

  #wrapper {
    padding-top: 115px;
  }

  .breadcrumb-div {
    position: fixed;
    margin-top: 0;
    width: 100%;
    background: #fff;
    z-index: 2;
  }

  .breadcrumb-div:after {
    content: '';
    background: #e9530e;
    left: 0;
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
  }

  .support-baby-img img {
    width: 250px;
  }

  .result-img-mob img {
    width: 250px;
  }

  .speciality-box {
    margin-bottom: 0;
    padding: 15px 10px;
  }

  .our-commitment {
    margin-top: 20px;
  }

  .about-primary-table.table-block .table td {
    text-align: left;
    padding: 10px 10px 10px 20px;
    font-size: 14px;
  }

  .patient-fail {
    margin: 0 -35px;
  }

  .driver-primary.treatment-bg::before {
    padding-top: 38.6%;
  }

  .treatment-landscape .bg-white {
    padding: 0 0 9px 0;
  }

  .breadcrumb-item+.breadcrumb-item::before {
    padding-right: 5px;
    padding-left: 5px;
  }

  .pivotal-trial ul {
    padding-left: 15px;
    margin-bottom: 10px;
  }

  .mobile-safety-img {
    margin-bottom: 30px !important;
    margin: 0;
  }

  .safety ul {
    margin-left: 0 !important;
    padding-left: 0;
    margin-bottom: 30px;
  }

  .safety li {
    line-height: 1.5;
  }

  .safety li::before {
    top: 3px;
  }

  .primary-endpoint .text-center {
    text-align: left !important;
  }

  .mobile-open {
    display: block;
  }

  .mobil-hide {
    display: none;
  }

  .rate-response ul {
    margin-top: 20px;
    margin-bottom: 0px !important;
  }

  .primary-endpoint-content p br {
    display: none;
  }

  .chart-bg {
    padding: 15px;
  }

  .driver-primary.treatment-bg {
    padding-top: 80px;
  }

  .driver-primary.treatment-bg::before {
    margin-top: -102px;
    background-size: 100%;
    z-index: 1;
    background-position: 0;
    padding-top: 169px;
  }

  .align-items-center.mobile-bottom {
    align-items: flex-end !important;
    margin-bottom: 10px;
    margin-top: 0 !important;
  }

  .align-items-center.mobile-bottom .cal-property h2 {
    margin-bottom: 0;
  }
}

/*homepage css*/
@media (max-width: 567px) {
  .boy-img {
    position: relative;
    margin: 0 0 0 0;
    padding: 60px 20px 0;
    clear: both;
    overflow: hidden;
  }

  .home-page-padding .boy-img::before {
    height: 40px;
    display: block;
    background-size: 100% 200%;
  }

  .section-bg.home-bottom.intro-block {
    padding-top: 0 !important;
    z-index: 2;
    position: relative;
  }

  .boy-img img {
    margin: 0 auto;
    position: absolute;
    left: auto;
    right: -30px;
    top: 20px;
    max-width: 170px;
  }

  .highlight-text {
    width: 62%;
    float: left;
    font-size: 14px;
  }

  .home .highlight-text {
    width: 100%;
  }

  .discover-content {
    padding: 0 15px 10px;
  }

  .product-work {
    padding: 0 0 20px 0;
  }

  .banner-content strong {
    font-size: 12px;
  }

  .product-work h3 {
    font-size: 16px;
  }

  .discover-data h2,
  .banner-content h1 {
    font-size: 33px;
    margin-bottom: 2px;
  }

  .discover-data h2 {
    margin-bottom: 14px;
  }

  .banner-content h4 {
    font-size: 25px;
    margin-bottom: 8px;
  }

  .banner-content .site-button {
    margin-top: 5px;
    font-size: 12px;
    padding: 10px 20px;
  }

  .product-work strong {
    font-size: 12px;
    margin-bottom: 0;
  }

  .work-box-details p {
    font-size: 14px;
  }

  .work-box-details a.read-more {
    font-size: 14px;
  }

  .discover-bg h3 {
    font-size: 16px;
  }

  .inner-section strong {
    margin-bottom: 5px;
  }

  .inner-section h4,
  .inner-section a {
    font-size: 16px;
  }

  .looking-more-block h3 {
    font-size: 16px;
  }

  .discover-bg p {
    font-size: 14px;
  }

  .work-box {
    padding-bottom: 0;
  }

  .work-box-heading {
    border-top: 1px solid #cccfd4;
    padding: 10px 50px 10px 0;
    position: relative;
  }

  .work-box-heading:after {
    content: '';
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -4px;
    border-top: solid 7px #e85611;
    border-left: solid 7px transparent;
    border-right: solid 7px transparent;
  }

  .work-box-details {
    display: none;
    padding-bottom: 20px;
  }

  .inner-section p {
    font-size: 14px;
  }

  .section-bg .bg-video-container {
    left: -40px;
  }

  .work-box-heading.open:after {
    border-top: 0;
    border-bottom: solid 8px #e85611;
  }

  .border-bottom-div {
    border-bottom: 1px solid #cccfd4;
  }

  .progress-circle {
    position: relative;
    height: 186px;
    width: 186px;
    margin: 0 auto 10px;
    top: 0;
    left: 0;
    right: 0;
    margin-top: -130px;
  }

  .progress-circle div {
    height: 186px;
    width: 186px;
  }

  .progress-circle div span {
    font-size: 40px;
    height: 115px;
    width: 115px;
    line-height: 115px;
    transform: translateX(-50%) translateY(-50%);
    box-shadow: none;
    border: 2px solid #08b0a0;
    color: #08b0a0;
    font-weight: 700;
  }

  .progress-circle .rotate {
    clip: rect(0 93px 186px 0);
  }

  .progress-circle .left {
    clip: rect(0 93px 186px 0);
    opacity: 1;
  }

  .progress-circle .right {
    clip: rect(0 93px 186px 0);
    transform: rotate(180deg);
    opacity: 0;
  }

  .discover-bg>.row {
    width: auto;
  }

  .discover-data .progress-circle {
    left: 15px;
  }

  .isi-block .information-detail .site-list li {
    font-size: 12px;
  }

  .isi-block .information-row1 {
    margin-bottom: 0;
  }

  .isi-block .information-row1 {
    padding-top: 15px;
  }

  .isi-block .information-row1 p {
    font-size: 12px;
    line-height: 1.1;
    margin-bottom: 0;
  }

  .isi-block .information-row1 h6 {
    margin-bottom: 0;
    font-size: 16px;
  }

  .isi-block .information-detail h4 {
    font-size: 18px;
    margin-top: 17px;
  }

  .isi-block .information-detail p {
    font-size: 12px;
    line-height: 1.4;
  }

  .isi-wrap .isi-block {
    height: 166px;
  }

  .isi-wrap .isi-block.minimized {
    height: 40px;
  }

  .isi-wrap .isi-block.minimized .information-row1 h6 {
    margin-bottom: 10px;
  }

  .isi-wrap .isi-block.expanded {
    height: auto;
  }

  /*======================================================*/
  /*About Primary HLH*/
  .section-bg {
    background-size: auto 26% !important;
  }

  .about-primary {
    background-size: auto 100% !important;
    padding: 20px 0;
    background: none !important;
  }

  .breadcrumb-div .breadcrumb a {
    font-size: 12px;
  }

  .breadcrumb-item.active {
    font-size: 12px;
  }

  .banner-text {
    padding: 23px 0;
  }

  /*.banner-box { min-height:168px;  }*/
  .tbl-heading {
    padding: 35px 10px;
  }

  .banner-text strong,
  .banner-text span {
    margin-bottom: 0;
  }

  .banner-text h1 {
    font-size: 28px;
    line-height: 1.1;
    margin-bottom: 0;
  }

  .banner-box .section-bg {
    background-size: cover !important;
    padding: 42px 0;
  }

  .survival-patients-img {
    display: none;
  }

  .survival-patients-text {
    margin-left: 20px;
    margin-top: 10px;
    width: 100%;
  }

  .survival-patients-text h3 {
    font-size: 14px;
  }

  .symptoms-text ul li {
    margin-bottom: 5px;
  }

  .about-primary-table.table-block .table thead th {
    font-size: 16px;
  }

  .table-block .table tbody th {
    font-size: 16px;
    padding: 18px 20px 18px 20px;
  }

  .about-primary-table.table-block .table td {
    padding: 10px 20px 10px 20px;
  }

  .about-primary-table.table-block {
    padding: 50px 0 30px;
  }

  .table-block .table {
    table-layout: fixed;
  }

  .diagnosis-challenging {
    position: relative;
    padding: 20px !important;
    padding-bottom: 0 !important;
  }

  .diagnosis-challenging .how-it-work-content h3 {
    padding-right: 100px;
  }

  .diagnosis-challenging figure {
    position: static;
    right: 0;
    top: -60px;
    max-width: 200px;
  }

  .diagnosis-challenging .col-12 {
    position: static;
  }

  .diagnosis-challenging .how-it-work-content p {
    margin-bottom: 10px;
  }

  .diagnosis-challenging figure img {
    margin-top: 0;
  }

  .accordion-databox .accordion-row .accordion-trigger {
    padding: 20px 60px 20px 20px;
    font-size: 16px;
  }

  .accordion-databox .accordion-row .accordion-trigger::after {
    right: 20px;
  }

  .accordion-trigger span {
    font-size: 14px;
  }

  .accordion-databox .accordion-row .accordion-trigger span {
    display: block;
    font-size: 14px;
    margin-top: 5px;
  }

  .accordion-box {
    padding: 30px 0;
  }

  .symptoms-img img {
    max-width: 72px;
    min-width: 100%;
  }

  .further-testing {
    padding: 40px 0;
  }

  .navigation-link h4 {
    font-size: 14px;
  }

  .navigation-btn .breadcrumb li {
    font-size: 14px;
  }

  .patient-fail .progress-circle {
    margin-top: -130px;
  }

  .patient-fail {
    padding-top: 80px !important;
  }

  /*Dosing Page
============================================================*/
  .prepare-and-administer .tab-border {
    border: 2px solid #67686a;
    padding: 0;
  }

  .tabs-content-box {
    padding: 10px;
  }

  .prepare-and-administer .nav-tabs .nav-item a span {
    width: 20px;
    height: 20px;
    margin-bottom: 8px;
  }

  .prepare-and-administer .nav-tabs .nav-item {
    width: 20%;
    margin: 0;
    margin-top: -10px;
  }

  .prepare-and-administer .nav-tabs .nav-item a {
    font-size: 10px;
    font-weight: 400;
    padding: 0 6px;
    word-break: break-all;
  }

  h3 {
    font-size: 16px;
  }

  .list-data ul li span {
    font-size: 12px;
  }

  .tabs-content-box ul li {
    margin-bottom: 10px;
    width: 100%;
  }

  .prepare-and-administer .speciality-heading {
    padding: 20px 0 30px;
    font-size: 14px;
  }

  .tabs-content-box h3 {
    text-align: center;
    margin-bottom: 20px !important;
  }

  .icon-cal {
    display: none;
  }

  .cal-content {
    width: 100%;
  }

  .dilution-tab4 {
    padding-left: 30px;
    margin-bottom: 20px;
  }

  .dilution-tab4::before {
    font-size: 26px;
  }

  .cal-property .form-inline .form-control {
    height: 72px;
    width: 100%;
    font-size: 30px;
    text-align: center !important;
  }

  .align-items-center {
    align-items: flex-start !important;
  }

  p {
    font-size: 12px;
  }

  h4.heading {
    font-size: 14px;
    line-height: 1.5;
  }

  .cal-content p {
    font-size: 13px;
    line-height: 1.3;
  }

  .cal-property .form-group {
    margin-bottom: 0 !important;
  }

  .mt-5,
  .my-5 {
    margin-top: 18px !important;
  }

  .tabs-content-box .col-md-7.col-7.d-flex.align-items-start {
    padding-right: 0 !important;
  }

  .radio-btn {
    width: 50%;
    float: left;
  }

  .cal-property h2 {
    font-size: 16px;
    margin-bottom: 15px;
    white-space: nowrap;
  }

  .access-ganifant {
    background: #ddeff5;
    padding: 5px 10px 15px;
    margin-top: 10px;
  }

  .access-ganifant figure {
    display: none;
  }

  .gamifant-offers figure {
    margin-bottom: 0;
    margin-top: 30px;
  }

  .gamifant-offers {
    padding: 30px 0;
  }

  .padding {
    padding: 30px 0;
  }

  .smear .tbl-heading {
    padding: 25px 10px;
  }

  .smear .how-it-work-content h3 {
    font-size: 16px;
    text-align: left;
  }

  .smear .how-it-work-content p {
    font-size: 14px;
    text-align: left;
  }

  .table-block .table tbody th {
    padding: 10px 10px 0 10px;
    font-size: 14px;
    width: auto;
  }

  .cal-property [type='radio']:checked+label,
  .cal-property [type='radio']+label {
    padding-left: 47px;
    line-height: 37px;
    font-size: 18px !important;
    margin-bottom: 10px;
  }

  .cal-property [type='radio']:checked+label::before,
  [type='radio']:not(:checked)+label::before {
    width: 37px;
    height: 37px;
  }

  .cal-property [type='radio']:checked+label::after,
  [type='radio']:not(:checked)+label::after {
    content: '';
    width: 27px;
    height: 27px;
    top: 5px;
    left: 5px;
    background: #0bb0a0;
  }

  /*Support-Resource
=================================*/
  .mobi-hide {
    display: none;
  }

  .support-resource-product.owl-carousel {
    display: block;
    padding-bottom: 60px;
  }

  .owl-dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .support-resource-product.owl-carousel .owl-dot.active {
    background: #08b0a0;
  }

  .product-name i {
    font-size: 22px;
  }

  .support-resource-product.owl-carousel .owl-item img {
    display: block;
    width: auto;
  }

  /*About Primary HLH
===========================================*/
  .breadcrumb-div ol li {
    font-size: 12px;
  }

  .support-baby-title {
    text-align: left;
    line-height: 1.2;
  }

  .safety {
    margin-top: 30px;
  }

  .safety ul {
    margin-left: 30px;
    margin-top: 0;
    margin-bottom: 25px;
  }

  .pivotal-trial .safety-hide {
    display: none;
  }

  .mobile-safety-img {
    display: block;
    width: 500px;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  .primary-endpoint-content {
    margin-top: 0;
    text-align: center;
  }

  .navigation-btn {
    display: none;
  }

  .population-section .col-md-12 {
    min-height: auto;
  }

  h4.item-description::before {
    left: -14%;
  }

  .primary-endpoint-content {
    text-align: left;
  }

  .neutralize .how-it-work-content {
    width: 100%;
    padding-left: 0;
  }

  .grey-bgcolor.neutralize::after {
    width: 100%;
    height: 296px;
    background-size: 260px;
    left: auto;
    right: 0;
    z-index: 2;
  }

  .neutralize .how-it-work-content {
    margin-top: 80px;
  }

  .neutralize .accordion-databox {
    margin-top: 0;
  }

  .accordion-data {
    padding: 0px 10px 20px 10px;
  }

  .mobile-show {
    display: block;
  }

  /*Access*/
  .banner-text h1 sup {
    font-size: 15px;
    top: -0.75em;
  }

  h3.speciality-heading {
    font-size: 14px;
    padding: 10px 10px;
  }

  .speciality-box h4 {
    font-size: 14px;
  }

  .speciality-box ul.contact-list li a {
    font-size: 14px;
  }

  .speciality-box ul.contact-list li {
    margin-bottom: 10px;
  }

  .speciality {
    padding-bottom: 0;
  }

  .our-commitment figure {
    display: none;
  }

  .how-it-work-container {
    margin-bottom: 20px;
  }

  .how-it-work-container .read-more {
    font-size: 16px;
  }

  .how-it-work-container .read-more+p {
    font-size: 14px;
  }

  .start-form-container .row {
    text-align: center;
  }

  .start-form-container figure img {
    width: 40%;
  }

  .start-form-container .survival-patients-text:before {
    left: -14px;
  }

  .starting-treatment-header {
    padding: 0 15px;
    margin-bottom: 20px;
  }

  .starting-treatment-list {
    margin-bottom: 20px;
    padding-left: 0;
    width: 100%;
    margin-top: 40px;
  }

  .starting-treatment-list:before {
    top: -40px;
  }

  .starting-treatment-list li {
    margin-bottom: 10px;
  }

  .starting-treatment-list p {
    font-size: 14px;
  }

  .starting-treatment-download {
    margin: 0 auto;
    width: 50%;
  }

  .starting-treatment-download .product-name {
    text-align: center;
  }

  /*results
	=================================*/
  .rate-response ul li {
    line-height: 1.4;
    margin-bottom: 8px;
  }

  .rate-response ul li::before {
    top: 8px;
  }

  .footer-block p br:first-child {
    display: block;
  }

  .footer-block p br {
    display: none;
  }

  .discover-data .row {
    margin: 0 -30px;
  }

  .discover-bg {
    padding: 35px 15px;
  }

  .patient-fail .discover-bg {
    padding: 35px 15px 10px !important;
  }

  .get-updates .d-inline-block {
    display: inline-block !important;
    width: 100%;
  }

  .support-resource-product.owl-carousel .owl-stage-outer {
    margin: 0 -1%;
  }

  .support-resource-product.owl-carousel .row.justify-content-between {
    justify-content: flex-start !important;
  }

  .support-resource-product.owl-carousel .row.justify-content-between .col-6:first-child {
    width: 40% !important;
    max-width: 40% !important;
  }

  .support-resource-product.owl-carousel .row.justify-content-between .col-6:last-child {
    width: 60% !important;
    max-width: 60% !important;
  }

  .border-bottom {
    border-bottom: 1px solid #cccfd4;
  }

  .driver-primary::before {
    left: 40%;
  }

  .driver-primary {
    margin-top: 100px;
  }

  .driver-primary::before {
    width: 480px;
    background-size: 490px;
    padding-top: 130px;
  }

  .about-primary-table.table-block {
    padding: 30px 0 30px;
  }

  .genetic-mutations .how-it-work-content {
    margin: 0;
  }

  #footer .information-detail {
    padding: 20px 0 10px 0;
  }

  .primary-endpoint p span {
    font-size: 12px;
  }

  .primary-endpoint-content h4.heading {
    margin-bottom: 0;
  }

  .driver-primary.treatment-bg {
    padding-top: 140px;
  }

  .driver-primary.treatment-bg::before {
    margin-top: -102px;
    background-size: 930px;
    z-index: 1;
    background-position: 110px 0px;
    padding-top: 169px;
  }
}

@media (max-width: 567px) {
  .looking-more-slider .owl-stage {
    left: -10px;
  }
}

@media (max-width: 479px) {
  .banner-content h4 {
    font-size: 25px;
  }

  .navigation-btn .breadcrumb li {
    font-size: 12px;
  }

  .navigation-link h4 {
    font-size: 12px;
  }

  .driver-primary .how-it-work-content {
    padding-top: 10px;
  }

  /*results
	=================================*/
  .rate-response {
    padding: 50px 20px 20px;
  }

  .rate-response .progress-circle div {
    height: 110px;
    width: 110px;
  }

  .rate-response .progress-circle div span {
    font-size: 23px;
    height: 70px;
    width: 70px;
    line-height: 67px;
  }

  .rate-response .progress-circle {
    height: 88px;
    width: 44px;
    position: absolute;
    left: -31px;
    margin-top: -70px;
  }

  .rate-response .progress-circle .rotate {
    clip: rect(0 55px 110px 0);
  }

  .rate-response .progress-circle .left {
    clip: rect(0 55px 110px 0);
    opacity: 1;
  }

  .rate-response .progress-circle .right {
    clip: rect(0 55px 110px 0);
    transform: rotate(180deg);
    opacity: 0;
  }

  .rate-response .rates.progress-circle {
    margin-bottom: 0;
    bottom: -20px;
    margin-top: 0;
    top: inherit;
  }

  .rate-response-text {
    margin-left: 0;
  }

  .rate-response-text {
    margin-top: -38px;
    margin-left: 20px;
  }

  .rate-response p {
    margin-left: 0;
  }

  .rate-response ul {
    margin-top: 0;
    margin-bottom: 40px !important;
    padding-left: 10px;
  }

  .col-top {
    padding-top: 0;
  }

  .rate-response h3 {
    font-size: 14px;
    margin-left: 0;
  }
}

/*@media (max-height:750px) {
	.information-detail { height:500px; overflow:auto }
 }
@media (max-height:650px) {
	.information-detail { height:420px; overflow:auto }
 }
@media (max-height:550px) {
	.information-detail { height:350px; overflow:auto }
 }
*/

/* for issues with content below the fold at 1366x768 */
@media (min-width: 1450px) {
  .diagnosis-graph-desc {
    margin-left: 30px;
  }
}

@media (min-width: 991px) and (max-width: 1440px) {
  .header-top {
    padding: 5px 0;
  }

  .logo img {
    max-width: 80%;
  }

  #main {
    /* margin-top: -28px; */
  }

  .banner-text {
    padding: 17px 0 26px 0;
  }

  .banner-text h1 {
    font-size: 54px;
  }

  .padding {
    padding: 40px 0;
  }

  #wrapper {
    padding-top: 112px;
  }

  .home #wrapper {
    padding-top: 142px;
  }
}

@media (min-width: 992px) {
  .menu>li .sub-menu {
    visibility: hidden;
    opacity: 0;
    transform: translateY(25px);
    position: absolute;
    top: 47px;
    left: 0;
    transition: all 300ms ease-in-out;
  }

  .menu>li:hover .sub-menu,
  .menu>li.active-menu>.sub-menu {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  .menu>li:hover:after,
  .menu>li.active:after {
    visibility: visible;
    opacity: 1;
    width: 100%;
  }

  .menu>li:hover a,
  .menu>li.active a {
    color: #e85611;
  }

  .menu>li>a.nav-trigger {
    padding-right: 30px;
  }

  .menu>li:hover>a.nav-trigger,
  .menu>li.active-menu>a.nav-trigger {
    background: #f2f5f7;
  }

  .menu>li .sub-menu li a:hover,
  .menu>li .sub-menu li.active>a {
    color: #f05323;
  }

  .support-baby {
    position: relative;
    left: -100px;
  }
}

@media (max-width: 1600px) {
  .driver-primary.treatment-bg::before {
    width: 100%;
    left: 0;
  }
}

.calc-header {
  background: #003d65;
  padding: 20px 40px;
  color: #fff;
  font-size: 24px;
  margin: 0 !important;
}

.calc-wrapper {
  border: 1px solid #979797;
  padding: 0 60px;
}

.calc-wrapper .results {
  margin: 0 -60px;
  padding: 0 60px;
  background: #f4f4f4;
}

.calc-wrapper .results .orange {
  color: #e85611;
}

.calc-wrapper .results .blue {
  color: #003d65;
}

.calc-wrapper .results .row:last-child {
  position: relative;
}

.calc-wrapper .results .row:last-child:before {
  content: '';
  position: absolute;
  border-top: 3px solid #dedede;
  top: -3rem;
  left: -45px;
  right: -45px;
}

.calc-wrapper .results .row:last-child a {
  color: #575656;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: underline;
}

.calc-wrapper figure {
  mix-blend-mode: multiply;
  text-align: center;
}

.calc-wrapper .results .formulas {
  display: none;
  clear: both;
  padding-top: 20px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: 100%;
}

.calc-wrapper .results .formulas.orange p {
  color: #e85611;
}

.calc-wrapper .results .formulas.blue p {
  color: #003d65;
}

.calc-wrapper .border-top.border-top-thick {
  position: relative;
}

.calc-wrapper .border-top.border-top-thick:after {
  content: '';
  position: absolute;
  border-top: 3px solid #dedede;
  top: 0;
  left: -45px;
  right: -45px;
}

.fake-input {
  float: left;
  margin-right: 18px;
  display: flex;
  height: 62px;
  align-items: center;
}

.fake-input:last-child {
  margin-right: 0;
}

.fake-input .value {
  background: #fff;
  border: 1px solid #979797;
  border-right: none;
  height: 100%;
  min-width: 105px;
  text-align: center;
  flex-grow: 1;
  line-height: 62px;
  font-size: 21px;
  color: #b2b2b2;
}

.fake-input .value.active-orange {
  color: #e85611;
  font-weight: 700;
}

.fake-input .value.active-blue {
  color: #003d65;
  font-weight: 700;
}

.fake-input .label {
  height: 100%;
  min-width: 49px;
  background: #e85611;
  color: #fff !important;
  text-align: center;
  line-height: 62px;
  font-size: 18px;
}

.fake-input .label.blue {
  background: #003d65;
}

@media screen and (max-width: 767px) {
  .intro-block-calc>.container {
    padding-left: 0;
    padding-right: 0;
  }

  .calc-header {
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px !important;
    padding-left: 20px;
    padding-right: 20px;
  }

  .calc-wrapper {
    border: none;
    padding: 0 15px;
  }

  .calc-wrapper .align-items-center {
    align-items: center !important;
  }

  .calc-wrapper figure {
    display: block;
  }

  .calc-wrapper h4.heading {
    font-size: 18px;
  }

  .calc-wrapper .radios {
    max-width: 320px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .calc-wrapper .radios .radio-btn {
    margin-top: 20px;
  }

  .calc-wrapper .results .row .col-md-7 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 20px;
  }

  .fake-input {
    margin-top: 20px;
  }

  .calc-wrapper .formulas {
    text-align: center;
  }

  .calc-wrapper .results .row .sm-pt-0 {
    padding-top: 10px !important;
  }

  .post-form-section .teal-text {
    text-align: left;
  }

  .grey-bg.mobile-white-bg {
    background: #fff;
    padding-top: 0;
  }

  .grey-bg.mobile-white-bg p {
    font-size: 14px;
  }

  .dilution-tab4 figure {
    display: inline-block;
  }

  .dilution-tab4 .align-items-center {
    align-items: center !important;
  }
}

h3.blue {
  color: #003d65;
}

.post-form-section .blue {
  color: #1b7db6;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.post-form-section .teal-text {
  font-size: 18px;
  font-weight: 700;
  color: #0bb0a0;
  line-height: normal;
}

.post-form-section .teal-text a {
  color: #0bb0a0;
  text-decoration: underline;
}

.post-form-section .btn-arrow {
  font-size: 12px;
}

.hang {
  display: block;
  text-indent: -6px;
}

.administration {
  padding: 30px;
  background: #003d65;
  color: #fff;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: $mobile-breakpoint) {
    margin-left: -30px;
    margin-right: -30px;
    width: calc(100% + 60px);
  }
}

.administration h4 {
  color: #0bb0a0;
  font-size: 24px;
  font-weight: 700;
}

.administration ul {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.administration ul li {
  margin-top: 30px;
}

.administration ul li a:hover {
  color: #eee;
}

@media screen and (max-width: 767px) {
  .administration img {
    display: none;
  }

  .administration ul {
    margin-left: 20px;
  }
}

.blue-txt {
  color: #1b7db6;
  font-weight: 700;
}

.teal-txt {
  color: #0bb0a0;
  font-weight: 700;
}

.teal-link {
  color: #0bb0a0;
  text-decoration: underline;
  font-weight: 700;
}

.question .img {
  padding-right: 22px;
  border-right: 2px solid #0bb0a0;
  margin-right: 22px;
  min-width: 99px;
}

/* How to order */
.how-to-order-table {
  max-width: 825px;
  margin-left: auto;
  margin-right: auto;
  color: #003d65;
}

.how-to-order-table img+p {
  padding-top: 16px;
}

.how-to-order-table .img-column img {
  padding-top: 16px;
}

.how-to-order-table .img-column p {
  padding-top: 6px;
}

.how-to-order-table p {
  color: #003d65;
  font-size: 14px;
  line-height: 19px;
}

@media screen and (max-width: 767px) {
  .how-to-order-table p {
    text-align: center;
  }
}

.how-to-order-table p.phone {
  padding-left: 40px;
  background: url(/images/icon-phone.png) left 2px no-repeat;
}

.how-to-order-table p.fax {
  padding-left: 40px;
  background: url(/images/icon-fax.png) left center no-repeat;
}

.how-to-order-table p.website {
  padding-left: 40px;
  background: url(/images/icon-website.png) left center no-repeat;
}

.how-to-order-table h2.title {
  background-color: #003d65;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin: 0 -15px;
  padding: 15px;
}

.how-to-order-table .row {
  background: rgba(232, 86, 17, 0.1);
  padding-top: 25px;
  padding-bottom: 25px;
}

.how-to-order-table .row.blue {
  background: rgba(0, 61, 101, 0.1);
}

.how-to-order-table .row .col-4:first-child {
  padding-left: 40px;
}

.how-to-order-table .row .col-4:first-child img {
  margin-bottom: 15px;
}

.how-to-order-table .arrow-box {
  position: relative;
  background: #183958;
  background: url('/images/bg-arrowhead.jpg') no-repeat top right #183958;
  background-size: 30px 100%;
  padding: 14px 20px 14px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 124px;
  margin-bottom: 14px;
}

@media screen and (min-width: 768px) {
  .how-to-order-table .arrow-box {
    margin-left: 20px;
    padding-right: 24px;
  }
}

.how-to-order-table .arrow-box.arrow-box--red {
  background: #e0572a;
  background: url('/images/bg-arrowhead-red.jpg') no-repeat top right #e0572a;
  background-size: 30px 100%;
}

.how-to-order-table .arrow-box.arrow-box--orange {
  background: #e77f50;
  background: url('/images/bg-arrowhead-orange.jpg') no-repeat top right #e77f50;
  background-size: 30px 100%;
}

.how-to-order-table .arrow-box p {
  color: #fff;
  font-style: italic;
  font-size: 14px;
  line-height: 19px;
  margin: 0;
  text-align: center;
}

.how-to-order-table .arrow-box+p {
  text-align: center;
}

.how-to-order-table .color-red p {
  color: #e0572a;
}

.how-to-order-table .color-orange p {
  color: #e77f50;
}

.show-on-mobile {
  display: block;
}

@media screen and (min-width: 768px) {
  .show-on-mobile {
    display: none;
  }
}

.show-on-desktop {
  display: none;
}

@media screen and (min-width: 768px) {
  .show-on-desktop {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .pt-28 {
    padding-top: 28px;
  }

  .pt-45 {
    padding-top: 45px;
  }
}

.contact-hsd-text {
  font-size: 18px;
  font-weight: 700;
  color: #e85611;
  text-transform: uppercase;
  line-height: normal;
}

.contact-hsd-text a {
  text-decoration: underline;
}

form ._error-inner {
  color: #f05323;
  font-weight: 700;
  padding: 5px 0;
}

#hsd-form-modal .modal-header {
  position: absolute;
  right: 10px;
}

#hsd-form-modal .modal-body {
  padding-left: 30px;
  padding-right: 30px;
}

.hsd-info,
.hsd-info a {
  color: #ff108b;
}

#hsd-form-modal .hsd-info,
#hsd-form-modal .hsd-info a {
  color: #575656;
}

@media screen and (max-width: 767px) {
  .how-to-order-table {
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 15px !important;
    padding-bottom: 25px !important;
  }

  .how-to-order-table .row {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .how-to-order-table .row .col-12 {
    width: auto;
    flex: 1;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .how-to-order-table .row .col-12:first-child img {
    margin-bottom: 10px;
  }

  .our-commitment figure {
    display: block;
  }

  .our-commitment .section-title {
    flex-direction: column-reverse;
    align-items: center !important;
  }

  .our-commitment .section-title h2 {
    padding: 0 15px !important;
    font-size: 20px;
    text-align: center;
    margin: 20px 0 0;
  }

  .our-commitment .d-flex {
    flex-direction: column;
    align-items: center !important;
  }

  .our-commitment .d-flex figure {
    margin: 15px 0;
  }

  .question .d-flex {
    flex-direction: column;
    align-items: center !important;
    text-align: left;
  }

  .question .d-flex .teal-txt {
    text-align: left;
    margin-top: 15px;
  }

  .survival-patients-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    text-align: center;
  }

  .survival-patients-box .survival-patients-text {
    margin: 0;
    padding: 0;
    border: none;
  }

  .survival-patients-box h3 {
    font-size: 18px;
    margin-top: 15px;
  }

  .question-wrap .question {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
  }

  .question-wrap .question .img {
    margin: 0 0 15px;
    padding: 0;
    border: none;
  }

  .question-wrap .question .teal-txt {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  .result-img-mob img {
    margin-top: -150px;
  }

  .dosing-img-mob img {
    margin-bottom: 50px;
  }
}

.owl-carousel.looking-more-slider .carousel-prev,
.owl-carousel.looking-more-slider .carousel-next {
  position: absolute;
  top: 50%;
}

.owl-carousel.looking-more-slider .carousel-prev {
  left: 0;
}

.owl-carousel.looking-more-slider .carousel-next {
  right: 0;
}

.resource-cta {
  background-color: #deedf5;
  padding: 35px 0;
}

.resource-cta .container {
  max-width: 1440px;
}

.resource-cta h3 {
  font-size: 20px;
  color: #003d65;
  text-transform: none;
  margin-bottom: 30px;
  text-align: center;
}

@media screen and (max-width: 1439px) {
  .resource-cta .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.cta-interstitial-modal {
  display: none;
  position: fixed;
  z-index: 100;
}

.cta-interstitial-modal .modal-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 930px;
  width: 930px;
  height: 500px;
  border: 5px solid #003d65;
}

.cta-interstitial-modal .modal-card .modal-close-icon {
  position: absolute;
  top: 28px;
  left: 28px;
  opacity: 1;
  background: none;
  color: #003d65;
  font-size: 28px;
  font-weight: 600;
  line-height: 1;
}

.cta-interstitial-modal .modal-card .modal-close-icon:focus {
  outline: 1px dotted;
}

.cta-interstitial-modal .modal-card p {
  color: #003d65;
  font-size: 28px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.53px;
  line-height: normal;
  text-align: left;
  max-width: 600px;
}

.cta-interstitial-modal .modal-card .button-container a {
  margin: 30px;
}

.cta-interstitial-modal .modal-card .btn {
  width: 265px;
  padding: 20px 10px;
  text-transform: none;
  font-size: 24px;
}

.cta-interstitial-modal .modal-card .btn.modal-close {
  background-color: #003d65;
  margin-left: 0;
}

.cta-interstitial-modal .modal-card .btn:not(.modal-close) {
  margin-right: 0;
}

.cta-interstitial-modal .modal-card .button-container {
  max-width: 600px;
}

.services-enroll.grey-bg a {
  margin-top: 0;
  font-size: 12px;
}

.text-dark-blue {
  color: #003d65;
}

.enroll-content.how-it-work-content h3 {
  font-size: 24px;
}

.services-enroll .how-it-work-content ul li {
  line-height: 27px;
}

@media screen and (max-width: 1000px) {
  .cta-interstitial-modal .modal-card {
    width: 650px;
  }
}

@media screen and (max-width: 768px) {
  .cta-interstitial-modal .modal-card {
    width: 80%;
  }

  .cta-interstitial-modal .modal-card .button-container a {
    margin: 0 0 15px 0;
  }

  .cta-interstitial-modal .modal-card .btn {
    width: 210px;
    font: 18px;
  }

  .cta-interstitial-modal .modal-card p {
    font-size: 22px;
  }

  .services-enroll.grey-bg a {
    margin: 0 auto 30px;
    display: block;
    width: 250px;
  }

  .services-enroll .blue-txt {
    margin-left: 20px;
    margin-right: 20px;
  }

  section.resources {
    margin-bottom: 0;
  }

  .resources-wrap.section-bg {
    padding: 0 0 40px !important;
  }

  .resources-wrap h3 {
    text-align: center;
    font-size: 20px;
  }

  .resources-wrap .question {
    margin-top: 30px;
  }

  .enroll-content.how-it-work-content h3 {
    font-size: 20px;
  }

  .resources.orange .resource:last-of-type {
    border-bottom: none;
  }
}

@media (max-width: 479px) {
  .rate-response>.row>.col-lg-6:nth-child(2) {
    margin-bottom: 10px;
  }
}

.home .discover-data .progress-circle div:nth-of-type(2) {
  transform: none !important;
}

.home .discover-data.shown .progress-circle div:nth-of-type(2) {
  transform: rotate(226.8deg) !important;
}

@media (max-width: 991px) {
  .tertiary-menu {
    padding-left: 25px;
  }
}
